import { toast } from "react-toastify";
import addProduct from "../../../services/post-add-product";
import "./index.scss";
import { Formik } from "formik";
import EditProduct from "../../../services/put-product";
import deleteProduct from "../../../services/delete-product";
import ReactSelect from "react-select";
import { Unit } from "../../../utils";
import { useState } from "react";
const dataState = {
  name: "",
  type: "",
  unit: "",
  price: "",
  weight: "",
  quantity: "",
};
const ModalAddProduct = ({
  closeAddModal = () => {},
  categoryId,
  doGetProductsByCategoryStore,
  editMode,
  productId,
  productToEditData,
  setDeletePermission,
  deletePermission,
  storeId,
  doGetGeneralData
}: any) => {
  const [loading, setLoading] = useState(false);
  const doAddProduct = (data: any, categoryId: any) => {
    setLoading(true);
    const bodyData = {
      name: data?.name,
      portion: Number(data?.weight),
      unit: data?.unit,
      Category: categoryId,
      cost: data?.price,
      expirationDate: "2023-12-31T23:59:59.999Z",
    };
    addProduct(bodyData)
      .then((response) => {
        setLoading(false);

        if (response.data.success) {
          closeAddModal();
          toast.success("product added successfuly !");
        
            doGetGeneralData()
         
        } else {
          if (response.data.status === 500) {
            toast.error("server error !");
            return;
          }
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  const doEditProduct = (values: any) => {
    const data = {
      name: values?.name,
      cost: values?.price,
      portion: values?.weight,
      unit: values?.unit,
    };
    EditProduct(data, productId).then((response) => {
      if (response.data.success) {
        toast.success("product updated successfuly !");
        doGetGeneralData()
        closeAddModal();
      } else {
        if (response.data.status === 500) {
          toast.error("server error !");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
      }
    }).catch((error)  => {
      console.log(error);
      
    })
  };

  const doDeleteProduct = () => {
    setDeletePermission(true);
  };

  const doDeleteProductAction = (productId: any, storeId: any) => {
    setLoading(true);
    deleteProduct(productId, storeId)
      .then((response) => {
        setLoading(false);

        if (response.data.status === 500) {
          toast.error("server error !");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success("product deleted successfuly !");
          doGetGeneralData()
          closeAddModal();
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };
  console.log(productId);
  return (
    <>
      {deletePermission ? (
        <div className="delete-permission-container">
          <div className="delete-permission-body">
            <div className="product-info">
              <span>Êtes Vous sure de Supprimer le produit</span>
              <span className="prod-name-txt">{productToEditData?.name}</span>
            </div>
          </div>
          <div className="delete-permission-footer">
            <button
              className="delete-cancel-btn"
              onClick={() => closeAddModal()}
            >
              Non
            </button>
            <button
              className="delete-confirm-btn"
              onClick={() => doDeleteProductAction(productId, storeId)}
            >
              Oui
            </button>
          </div>
        </div>
      ) : (
        <div className="modal-add-prod-container">
          <div className="modal-add-prod-header">
            <p className="new-prod-txt">
              {" "}
              {editMode && Boolean(productId)
                ? "Informations de Produit "
                : "Nouveau Produit"}{" "}
            </p>
            <i
              onClick={() => closeAddModal()}
              className="bx bx-x"
              style={{ fontSize: 28, fontWeight: "400", color: "#000" }}
            ></i>
          </div>
          <div className="modal-add-prod-body">
            <Formik
              initialValues={Boolean(productId) ? productToEditData : dataState}
              onSubmit={(values: any, { setSubmitting }) => {
                setTimeout(() => {
                  if (Boolean(productId) && editMode) {
                    doEditProduct(values);
                  } else {
                    doAddProduct(values, categoryId);
                  }
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }: any) => (
                <form className="form-container" onSubmit={handleSubmit}>
                  <div className="first-row-container">
                    <div className="input-container">
                      <input
                        className="input-name-style"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.name}
                        placeholder="Nom"
                      />

                      <p
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        {errors.name && touched.name && errors.name}
                      </p>
                    </div>
                    <div className="input-container">
                      <input
                        className="input-type-style"
                        type="number"
                        name="price"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.price}
                        placeholder="Prix"
                      />

                      <p
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        {errors.price && touched.price && errors.price}
                      </p>
                    </div>
                  </div>
                  <div
                    className="second-row-container"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div className="input-container">
                      <input
                        className="input-price-style"
                        type="text"
                        name="weight"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.weight}
                        placeholder="Poids"
                      />

                      <p
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        {errors.weight && touched.weight && errors.weight}
                      </p>
                    </div>
                    <div className="input-container">
                      <ReactSelect
                        name="unit"
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Select unit..."
                        options={[
                          { value: Unit.KG, label: "Kilograme" },
                          { value: Unit.G, label: "Gramme" },
                          { value: Unit.L, label: "Litre" },
                        ]}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: "unit",
                              value: selectedOption ? selectedOption.value : "",
                            },
                          });
                        }}
                        value={
                          values.unit && {
                            value: values.unit,
                            label: values.unit.toUpperCase(),
                          }
                        }
                      />

                      <p
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        {errors.unit && touched.unit && errors.unit}
                      </p>
                    </div>
                  </div>
                  {editMode && Boolean(productId) ? (
                    <div className="modal-add-prod-footer-edit-mode">
                      <div className="delete-btn-container">
                        <button
                          className="delete-btn"
                          onClick={() => doDeleteProduct()}
                        >
                          Supprimer
                        </button>
                      </div>
                      <div className="cancel-and-edit-btns-container">
                        <button
                          className="cancel-btn"
                          onClick={() => closeAddModal()}
                        >
                          Annuler
                        </button>
                        <button className="confirm-btn">Confirmer</button>
                      </div>
                    </div>
                  ) : (
                    <div className="modal-add-prod-footer">
                      <button
                        className="modal-add-prod-btn-cancel-style"
                        onClick={() => closeAddModal()}
                      >
                        Annuler
                      </button>
                      <button
                        className="modal-add-prod-btn-add-style"
                        type="submit"
                        disabled={loading}
                      >
                        Confirmer
                      </button>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};
export default ModalAddProduct;
