import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HeaderComponent from "../Header";
import ListProviders from "../ListProviders";
import ModalProvider from "../modals/ModalProvider";
import Modal from "react-modal";
import getAllProviders from "../../services/get-all-providers";
import getProvidersPagination from "../../services/get-providers-pagination";
import editProvider from "../../services/put-provider";
import searchProvider from "../../services/post-search-provider";
import Layer from "../Layer";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "50vh",
    minHeight: 400,
    // width: "30%",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
const ProvidersScreen = () => {
  const [modalProvidersIsOpen, setModalProvidersIsOpen] = useState(false);
  const [listProviders, setListProviders] = useState<any>([]);
  const [currentProvider, setCurrentProvider] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [totalePages, setTotalePages] = useState(1);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const closeAddProviderModal = () => {
    setModalProvidersIsOpen(false);
    setDeletePermission(false);
    setEditMode(false);
    setCurrentProvider(null);
  };

  const doGetProviders = (page: any, limit: any) => {
    setLoading(true);
    getProvidersPagination(page, limit)
      .then((response: any) => {
        setLoading(false);

        if (response?.data?.success) {
          setListProviders([...listProviders, ...response?.data?.content]);
          setTotalePages(response?.data?.pagination?.totalPages);
        } else {
          toast.error(`Error : ${response?.data?.error}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };
  const doGetProvidersInit = (page: any, limit: any) => {
    setLoading(true);
    getProvidersPagination(page, limit)
      .then((response: any) => {
        setLoading(false);

        if (response?.data?.success) {
          setPage(1);

          setListProviders(response?.data?.content);
          setTotalePages(response?.data?.pagination?.totalPages);
        } else {
          toast.error(`Error : ${response?.data?.error}`);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };
  const doGetAllProviders = () => {
    setLoading(true)

    getAllProviders()
      .then((response: any) => {
        setLoading(false)

        console.log(response);

        if (response?.data?.success) {
          setListProviders(response?.data?.content);
        } else {
          toast.error(`Error : ${response?.data?.message}`);
        }
      })
      .catch((error) => {
        setLoading(false)

        toast.error(error);
      });
  };
  useEffect(() => {
    if (!Boolean(query)) {
      doGetProvidersInit(1, 5);
    }
  }, [query]);

  console.log(listProviders);

  const loadMore = () => {
    const newPage = page + 1;
    setPage(page + 1);
    doGetProviders(newPage, limit);
  };

  const doUpdateProvider = (id: any, data: any) => {
    editProvider(data, id)
      .then((response) => {
        if (response?.data?.success) {
          toast.success(response?.data?.message);
          doGetProvidersInit(1, 5);
          closeAddProviderModal();
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const doHandleSearchProvider = (query: any) => {
    const data = { query: query };
    searchProvider(data)
      .then((response) => {
        if (response?.data?.success) {
          setListProviders(response?.data.content);
        } else {
          toast?.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  console.log("query", query);

  return (
    <>
      <Layer title={"providers"}>
        <HeaderComponent
          title="Fournisseurs"
          setModalProvidersIsOpen={setModalProvidersIsOpen}
          setQuery={setQuery}
          doHandleSearchProvider={doHandleSearchProvider}
        />
        <div style={{ height: "100%", width: "100%" }}>
          {loading ? (
            <Lottie
              options={defaultOptionsLoading}
              height={"60%"}
              width={"30%"}
            />
          ) : (
            <ListProviders
              listProviders={listProviders}
              setIsOpen={setModalProvidersIsOpen}
              setCurrentProvider={setCurrentProvider}
              productId={currentProvider?._id}
              setEditMode={setEditMode}
              loadMore={loadMore}
              totalePages={totalePages}
              page={page}
              query={query}
            />
          )}
        </div>
      </Layer>
      <Modal
        isOpen={modalProvidersIsOpen}
        onRequestClose={closeAddProviderModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalProvider
          closeAddProviderModal={closeAddProviderModal}
          doGetAllProviders={doGetAllProviders}
          editMode={editMode}
          productId={currentProvider?._id}
          productToEditData={currentProvider}
          setDeletePermission={setDeletePermission}
          deletePermission={deletePermission}
          doGetProviders={doGetProvidersInit}
          doUpdateProvider={doUpdateProvider}
        />
      </Modal>
    </>
  );
};

export default ProvidersScreen;
