import "./index.scss";

import { useNavigate } from "react-router-dom";
import { sideBarContent } from "../../utils";

function SideBar({ routePage }: any): JSX.Element {
  const navigate = useNavigate();

  return (
    <div className="sidebar-content">
      <div style={{ minWidth: 180, minHeight: 180 }} className="logo-container">
        <img
          style={{ objectFit: "contain", width: 100, height: 100 }}
          src="./../../../../assets/logoInventor.png"
          alt="Logo"
        />
        <div className="logo-title-container">
          <span>Pizzaïe</span>
          <span>Inventory</span>
        </div>
      </div>
      <div className="button-container">
        {sideBarContent?.map((object) => (
          <button
            className={
              routePage === object?.route ? "clicked-btn" : "sidebar-button"
            }
            onClick={() => navigate(`/${object?.route}`)}
          >
            <div style={{ width: "30%" }}>
              <img
                src={`./../../../../assets/${object?.icon}.png`}
                alt="Stores"
              />
            </div>
            <div className="button-label">{object?.title}</div>
          </button>
        ))}
      </div>
    </div>
  );
}

export default SideBar;
