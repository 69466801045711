import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../SideBar";
import "./index.scss";
import HeaderComponent from "../../Header";
import { ReactComponent as StoreKpiImage } from "./StoreKpiImage.svg";
import { ReactComponent as Runner } from "./availableRunner.svg";

import { ReactComponent as ProductLimit } from "./prodLimit.svg";
import getProductLimitKpi from "../../../services/get-product-limit";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";
import getStoreReptureKpi from "../../../services/get-store-repture";
import { getSocketInstance } from "../../../services/socket";
import getRunnersMq from "../../../services/get-runners-mq";
import { AppContext, AppContextType } from "../../Context/AppContext";
import Layer from "../../Layer";

const HomeScreen = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [loadingProdLimit, setLoadingProdLimit] = useState(false);
  const [loadingStoreRepture, setLoadingStoreRepture] = useState(false);
  // const [loadingDriverNumber, setLoadingDriverNumber] = useState(false);
  const [runners, setRunners] = useState(null);
  const [productLimitValue, setProductLimitValue] = useState<any>(0);
  const [storeReptureValue, setStoreReptureValue] = useState<any>(0);
  // const [driverNumberValue, setDriverNumberValue] = useState<any>(0);
  const socket = getSocketInstance();

  const { availableRunners, setAvailableRunners } = React.useContext(
    AppContext
  ) as AppContextType;
  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const doGetProductLimit = () => {
    setLoadingProdLimit(true);
    getProductLimitKpi()
      .then((response) => {
        setLoadingProdLimit(false);
       
        if (response?.data?.success) {
          setProductLimitValue(response?.data?.content);
        }else{
          if (response?.data?.status === 500) {
            toast.error("Internal server error !");
            return;
          }
          if (!response?.data?.success) {
            toast.error(response?.data?.message);
            return;
          }
        }
      })
      .catch((error) => {
        setLoadingProdLimit(false);

        toast.error(error);
        console.log(error);
      });
  };
  const doGetStoreRepture = () => {
    getStoreReptureKpi()
      .then((response) => {
        setLoadingStoreRepture(false);
        console.log(response?.data?.success);
        

        if (response?.data?.success) {
          setStoreReptureValue(response?.data?.content);
        } else {
          if (response?.data?.status === 500) {
            toast.error("Internal server error !");
            return;
          }
          if (!response?.data?.success) {
            toast.error(response?.data?.message);
            return;
          }
        }
      })
      .catch((error) => {
        setLoadingStoreRepture(false);

        toast.error(error);
        console.log(error);
      });
  };

  const doGetRunnersMq = () => {
    getRunnersMq()
      .then((response) => {
       
        if (response?.data?.success) {
          setAvailableRunners(response?.data?.content?.runnerscount);
        }else{
          if (response?.data?.status === 500) {
            toast.error("Internal server error !");
            return;
          }
          if (!response?.data?.success) {
            toast.error(response?.data?.message);
            return;
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    // doGetProductLimit();
    doGetStoreRepture();
    doGetRunnersMq();
  }, []);

  return (
    <Layer title={"home"}>
      <HeaderComponent title="Home" />

      <div style={{ height: "70vh", width: "100%", overflow: "auto" }}>
        <div className="kpis-container">
          <div className="col-md-4">
            <div className="card-container-h">
              <div className="title-kpi">
                <p className="title-txt">Stores en repture</p>
              </div>
              <div className="image-value-container">
                {loadingStoreRepture ? (
                  <div>
                    <Lottie
                      options={defaultOptionsLoading}
                      height={"40%"}
                      width={"20%"}
                    />
                  </div>
                ) : (
                  <div>
                    <p className="number-txt">{storeReptureValue}</p>
                  </div>
                )}
                <div>
                  <StoreKpiImage />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-container-h">
              <div className="title-kpi">
                <p className="title-txt">Produits en repture</p>
              </div>
              <div className="image-value-container">
                {loadingProdLimit ? (
                  <div>
                    <Lottie
                      options={defaultOptionsLoading}
                      height={"40%"}
                      width={"20%"}
                    />
                  </div>
                ) : (
                  <div>
                    <p className="number-txt-prod-limit">{productLimitValue}</p>
                  </div>
                )}
                <div>
                  <ProductLimit />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-container-h">
              <div className="title-kpi">
                <p className="title-txt">Livreurs Disponible</p>
              </div>
              <div className="image-value-container">
                {loadingProdLimit ? (
                  <div>
                    <Lottie
                      options={defaultOptionsLoading}
                      height={"40%"}
                      width={"20%"}
                    />
                  </div>
                ) : (
                  <div>
                    <p className="number-txt-prod-limit">{availableRunners}</p>
                  </div>
                )}
                <div>
                  <Runner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layer>
  );
};

export default HomeScreen;
