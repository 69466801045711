// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-products-container {
  min-height: 85vh;
  width: 100%;
  padding: 10px;
}

.animation-wrapper {
  height: 100%;
  width: 100%;
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/ProductDetailsScreen/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;AACF;;AACA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AAEJ","sourcesContent":[".section-products-container {\n  min-height: 85vh;\n  width: 100%;\n  padding: 10px;\n}\n.animation-wrapper{\n    height: 100%;\n    width: 100%;\n    padding: 20px;\n    justify-content: center;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
