import { useEffect, useState } from "react";
import SideBar from "../../SideBar";
import "./index.scss";
import HeaderComponent from "../../Header";
import { useParams } from "react-router-dom";
import ProdAchatItem from "../../ProdAchatItem";

const listProdsAchat = [
  { id: 1, name: "Pepper", quantity: 12, unity: "Kg", weight: 100 },
  { id: 1, name: "Pepper", quantity: 12, unity: "Kg", weight: 100 },
  { id: 1, name: "Pepper", quantity: 12, unity: "Kg", weight: 100 },
  { id: 1, name: "Pepper", quantity: 12, unity: "Kg", weight: 100 },
];

const NotificationDetailsScreen = () => {
  const [showMenu, setShowMenu] = useState(false);
  const id = useParams();
  console.log("id", id);

  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="row">
      <div
        className={`col-12 col-md-3 ${showMenu ? "show-menu" : ""}`}
        style={{
          background: `linear-gradient(184deg, #F8FFFD -2.42%, #FFF7F7 -2.41%, #F9FFFA 44.7%, #EEE 100.39%)`,
        }}
      >
        <SideBar routePage={""} />
      </div>
      <div className="col-12 col-md-9">
        <div className="content-products-and-bar">
          <HeaderComponent title="Achat n° 014552004" id={id} />
          <div className="prods-achat-container">
            {listProdsAchat?.length > 0 ? (
              listProdsAchat?.map((prodAchat, index) => (
                <div key={index}>
                  <ProdAchatItem prodAchat={prodAchat} />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotificationDetailsScreen;
