import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import getAllStores from "../../../services/get-all-stores";
import AcceptOrder from "../../../services/put-accept-order";
import "./index.scss";
import updateStatusPurchaseService from "../../../services/patch-purchaseService-status";

function ValidatePurchaseServicePermission({
  orderId,
  onClose,
  doGetPurchaceById,
}: any) {
  const [loading, setLoading] = useState(false);

  const doAcceptOrder = () => {
    const body = { status: "validated" };
    setLoading(true);
    updateStatusPurchaseService(orderId, body)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          toast.success("order validated !");
          doGetPurchaceById();
          onClose();
        }
      })
      .catch((error) => {
        setLoading(false);
        // toast.error("Erreur lors de l'acceptation de la commande");
      });
  };

  return (
    <div className="select-store">
      <div className="select-store-header">
        etes vous sure de vouloir accepter l'order ?
      </div>
      <div className="select-store-body"></div>
      <div className="select-store-footer">
        <button className="cancel-btn" onClick={onClose}>
          Annuler
        </button>
        <button
          className="confirm-btn"
          onClick={doAcceptOrder}
          disabled={loading}
        >
          {loading ? "Confirmation..." : "Confirmer"}
        </button>
      </div>
    </div>
  );
}

export default ValidatePurchaseServicePermission;
