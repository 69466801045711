const initialState ={ products: [], sender: {id : "" , name : ""}, receiver: {id : "" , name : ""} };

const transactionDataSlice = {
  initialState,
  reducers: {
    addProduct(state: any, action: any) {
      state.products.push(action.payload);
    },
  
 
    removeProduct(state: any, action: any) {
      console.log("sss", action.payload);

      state.products = state.products.filter(
        (product: any) => product?.value !== action.payload?.prod.value
      );
    },
    increaseQuantity(state: any, action: any) {
      const productIdToIncrease = action.payload.productId;
      const quantityToIncrease = action.payload.quantity;

      // Find the product in the state
      const productToIncrease = state.products.find(
        (product: any) => product._id === productIdToIncrease
      );

      if (productToIncrease) {
        // Increase the quantity of the product
        productToIncrease.quantity += quantityToIncrease;
      }
    },
    setReceiver(state: any, action: any) {
      state.receiver.name = action.payload.name;
      state.receiver.id = action.payload.id
    },
    resetList(state : any, action : any) {
      return { ...initialState };
    },
  },
};

export default transactionDataSlice;

export const PIZZA_SLICE_ADD_PRODUCT = "transactionDataSlice/addProduct";
export const RESET_LIST = "transactionDataSlice/resetList";
export const REMOVE_PRODUCT = "transactionDataSlice/removeProduct";
export const INCREASE_QUANTITY = "transactionDataSlice/increaseQuantity";
export const SET_RECEIVER = "transactionDataSlice/setReceiver";

