import { getRequest } from "../utils/axios";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

async function getTransactionsHistoryByProduct(
  page: any,
  limit: any,
  productId: any,
  storeId: any
): Promise<DataFromAxiosType> {
  try {
    const { data }: DataFromAxiosType = await getRequest(
      `/Transction/Get-Transaction/Product?page=${page}&limit=${limit}&productId=${productId}&storeId=${storeId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!data.success) {
      throw new Error(data?.message);
    }

    return {
      data,
    };
  } catch (error: any) {
    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: error?.response?.data?.message ?? "",
        status: error?.response?.status ?? 500,
        content: [],
      },
    };
    return returnedData;
  }
}

export default getTransactionsHistoryByProduct;
