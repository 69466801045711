import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import getAllStores from "../../../services/get-all-stores";
import AcceptOrder from "../../../services/put-accept-order";
import "./index.scss";

function SelectStore({ orderId, onClose , doGetPurchaceById }: any) {
  const [selectedStore, setSelectedStore] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [listStores, setListStores] = useState<any>([]);

  const doGetAllStores = () => {
    setLoading(true);
    getAllStores()
      .then((response: any) => {
        setLoading(false);
        if (response?.data?.status === 500) {
          toast.error("Internal server error");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setListStores(response?.data?.content);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Erreur de récupération des magasins");
      });
  };

  useEffect(() => {
    doGetAllStores();
  }, []);

  const doAcceptOrder = () => {
    if (!selectedStore) {
      toast.warning("Veuillez sélectionner un magasin");
      return;
    }

    setLoading(true);
    AcceptOrder(selectedStore?._id, orderId)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          toast.success("Stock alimenté avec succès !");
          doGetPurchaceById()
          onClose();
        }
      })
      .catch((error) => {
        setLoading(false);
        // toast.error("Erreur lors de l'acceptation de la commande");
      });
  };

  return (
    <div className="select-store">
      <div className="select-store-header">
        Sélectionner l'établissement à alimenter
      </div>
      <div className="select-store-body">
        {loading ? (
          <div>Chargement des magasins...</div>
        ) : (
          listStores?.filter((store: any) => store?.name !== "General Stock").map((store: any) => (
            <div
              key={store.id}
              className={`store-item ${selectedStore?._id === store._id ? "selected" : ""}`}
              onClick={() => setSelectedStore(store)}
            >
              <div className="store-icon"></div>
              <div className="store-info">
                <div className="store-name">{store.name}</div>
                <div className="store-address">{store.address}</div>
              </div>
              {selectedStore?._id === store._id && (
                <div className="store-checkmark">✔</div>
              )}
            </div>
          ))
        )}
      </div>
      <div className="select-store-footer">
        <button className="cancel-btn" onClick={onClose}>Annuler</button>
        <button className="confirm-btn" onClick={doAcceptOrder} disabled={loading}>
          {loading ? "Confirmation..." : "Confirmer"}
        </button>
      </div>
    </div>
  );
}

export default SelectStore;
