import { getRequest } from "../utils/axios";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

async function getServicesByPaginationUp(
  page: any,
  limit: any
): Promise<DataFromAxiosType> {
  try {
    const { data }: DataFromAxiosType = await getRequest(
      `/purchaseservices/purchaseservices?limit=${limit}&page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!data.success) {
      throw new Error(data?.message);
    }

    return {
      data,
    };
  } catch (error: any) {
    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: error?.response?.data?.message ?? "",
        status: error?.response?.status ?? 500,
        content: [],
      },
    };
    return returnedData;
  }
}

export default getServicesByPaginationUp;
