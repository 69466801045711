import SideBar from "../../SideBar";
import { useState, useEffect } from "react";
import "./index.scss";
import Modal from "react-modal";
import CardTransactionHistory from "../../CardTransactionHistory";
import HeaderComponent from "../../Header";
import getHistoryTransactionsByPagination from "../../../services/get-history-transactions";
import { toast } from "react-toastify";
import searchTransaction from "../../../services/search-query-transaction";
import Lottie from "react-lottie";
import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../../assets/lottie-animations/animationOptions";
import { formatDateAndTime } from "../../../utils";
import Layer from "../../Layer";
import DeleteProductPurchase from "../../modals/DeleteProductPurchase";
const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "50vh",
    width: "30%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const HistoryScreen = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState<any>();
  const [listTransactions, setListTransactions] = useState<any>([]);
  const [page, setPage] = useState<any>(1);
  const [limitPage, setLimitPage] = useState<any>(17);
  const [totalPages, setTotalPages] = useState<any>();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  function closeModal() {
    setIsOpen(false);
  }

  function formatProductArray(products: any, limit: any) {
    const slicedProducts = products.slice(0, limit);
    return slicedProducts.reduce(
      (accumulator: any, product: any, index: any) => {
        const { portion, unit, name } = product?.product ?? {
          portion: null,
          unit: null,
          name: null,
        };
        const totalQuantity = portion * product?.quantity;
        const productString = `${totalQuantity} ${unit} ${name}`;

        if (index === slicedProducts.length - 1) {
          return accumulator + productString;
        } else {
          return accumulator + productString + " , ";
        }
      },
      ""
    );
  }

  const doGetHistoryTransactionsByPagination = (page: any, limitPage: any) => {
    setLoading(true);
    getHistoryTransactionsByPagination(page, limitPage)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setListTransactions([
            ...listTransactions,
            ...response?.data?.content?.transactions,
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
        console.log(error);
      });
  };

  const doGetInitialeHistoryTransactionsByPagination = () => {
    setLoading(true);

    getHistoryTransactionsByPagination(1, 17)
      .then((response) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setListTransactions(response?.data?.content?.transactions);
          setTotalPages(response?.data?.content?.totalPages);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
        console.log(error);
      });
  };

  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    doGetInitialeHistoryTransactionsByPagination();
  }, []);
  useEffect(() => {
    if (!Boolean(query)) {
      doGetInitialeHistoryTransactionsByPagination();
      setPage(1);
      setLimitPage(17);
    }
  }, [query]);

  const loadMoreAction = (): void => {
    console.log(totalPages, page);

    if (totalPages <= page) {
      return;
    } else {
      setPage(page + 1);

      doGetHistoryTransactionsByPagination(page + 1, limitPage);
    }
  };
  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      console.log("bottom");
      loadMoreAction();
    }
  };

  const searchAction = () => {
    console.log("yess his");

    if (query.trim().length <= 0) {
      return;
    }
    searchTransaction({ searchString: query })
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setListTransactions(response?.data?.content);
        }
      })
      .catch((error: any) => {
        console.log(error);
        toast.error(error);
      });
  };

  return (
    <>
      <Layer
        title={"history"}
        setQuery={setQuery}
        query={query}
        searchAction={searchAction}
      >
        <HeaderComponent
          title="Historique"
          query={query}
          setQuery={setQuery}
          searchAction={searchAction}
        />
        <div className="table-container" onScroll={handleScroll}>
          {loading ? (
            <div className="loading-content">
              <Lottie
                options={defaultOptionsLoading}
                height={"60%"}
                width={"30%"}
              />
            </div>
          ) : listTransactions.length > 0 ? (
            <table className="table-style">
              <thead style={{ height: 60 }}>
                <tr>
                  <th className="title-txt">De</th>
                  <th className="title-txt">À</th>
                  <th className="title-txt">Détails</th>
                  <th className="title-txt">Date</th>
                </tr>
              </thead>

              <tbody className="t-body" onScroll={handleScroll}>
                {listTransactions?.length > 0 ? (
                  listTransactions
                    ?.map((transaction: any, index: any) => {
                      return (
                        <tr
                          key={index}
                          className="line-table-style"
                          onClick={() => {
                            setIsOpen(true);
                            setCurrentTransaction(transaction);
                          }}
                        >
                          <td className="td-right">
                            <p className="td-right-text-style">
                              {transaction?.sender?.owner?.name}
                            </p>
                          </td>
                          <td className="td-center">
                            <p className="td-center-txt">
                              {" "}
                              {transaction?.receiver?.owner?.name}
                            </p>
                          </td>
                          <td className="td-center">
                            <p className="td-center-txt">
                              {formatProductArray(transaction?.details, 2)} ...
                            </p>
                          </td>
                          <td className="td-left">
                            <p className="td-left-txt">
                              {formatDateAndTime(transaction?.date)}
                            </p>
                          </td>
                        </tr>
                      );
                    })
                    .reverse()
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          ) : (
            <div className="loading-content">
              <Lottie
                options={defaultOptionsEmpty}
                height={"60%"}
                width={"30%"}
              />
            </div>
          )}
        </div>
      </Layer>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <CardTransactionHistory
          transaction={currentTransaction}
          closeModal={closeModal}
          currentTransaction={currentTransaction}
        />
      </Modal>
    </>
  );
};
export default HistoryScreen;
