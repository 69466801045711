const initialState = { products: [], services: [] , selectedServices : [] };

const bascketSlice = {
  initialState,
  reducers: {
    // Product-related reducers
    addProduct(state: any, action: any) {
      state.products.push(action.payload);
    },

    removeProduct(state: any, action: any) {
      state.products = state.products.filter(
        (product: any) => product?.value !== action.payload?.prod.value
      );
    },

    increaseProductQuantity(state: any, action: any) {
      const productIdToIncrease = action.payload.productId;
      const quantityToIncrease = action.payload.quantity;

      const productToIncrease = state.products.find(
        (product: any) => product.id === productIdToIncrease
      );

      if (productToIncrease) {
        const newQuantity = quantityToIncrease;
        if (newQuantity >= 1) {
          productToIncrease.quantity = newQuantity;
        }
      }
    },

    // Service-related reducers
    addService: (state: any, action: any) => {
      // Ensure state.services is defined and is an array before pushing
      if (!state.services) {
        state.services = []; // Initialize if undefined
      }
      state.services.push(action.payload); // Now you can safely push the payload
    },
    editService(state: any, action: any) {
      const { index, updatedService } = action.payload;
      console.log("index" , index);
      
    
      // Ensure index is within bounds and update the service
      if (index >= 0 && index < state.services.length) {
        state.services[index] = {
          ...state.services[index],
          ...updatedService,
        };
      }
    },
    
    removeService(state: any, action: any) {
      const index = action.payload.index;
      console.log("index ==>", index);

      // Ensure that services is an array
      if (Array.isArray(state.services)) {
        // Check if the index is within the valid range
        if (index >= 0 && index < state.services.length) {
          state.services.splice(index, 1); // Remove the service by index
          console.log(`Service at index ${index} removed successfully.`);
        } else {
          console.error(`Invalid index: ${index}. No service removed.`);
        }
      } else {
        console.error("state.services is not an array.");
      }
    },

    increaseServiceQuantity(state: any, action: any) {
      const serviceIdToIncrease = action.payload.serviceId;
      const quantityToIncrease = action.payload.quantity;

      const serviceToIncrease = state.services.find(
        (service: any) => service.id === serviceIdToIncrease
      );

      if (serviceToIncrease) {
        const newQuantity = quantityToIncrease;
        if (newQuantity >= 1) {
          serviceToIncrease.quantity = newQuantity;
        }
      }
    },
    addSelectedService(state: any, action: any) {
      console.log("ction.payload" , action.payload);
      
      state.selectedServices = [ ...state.selectedServices , action.payload]
    },

    removeSelectedService(state: any, action: any) {
      const indexToRemove = action.payload.index;
    
      // Ensure the index is within the bounds of the array
      if (indexToRemove >= 0 && indexToRemove < state.selectedServices.length) {
        const removedService = state.selectedServices[indexToRemove];
    
        // Remove the selected service by index
        state.selectedServices.splice(indexToRemove, 1);
    
        // Add the removed service back to the services list
       
      }
    },
    
    resetList(state: any, action: any) {
      return { ...initialState };
    },
  },
};

export default bascketSlice;

export const PIZZA_SLICE_ADD_PRODUCT_BASCKET = "bascketSlice/addProduct";
export const RESET_LIST_BASCKET = "bascketSlice/resetList";
export const REMOVE_PRODUCT_BASCKET = "bascketSlice/removeProduct";
export const INCREASE_PRODUCT_QUANTITY_BASCKET =
  "bascketSlice/increaseProductQuantity";

export const PIZZA_SLICE_ADD_SERVICE_BASCKET = "bascketSlice/addService";
export const PIZZA_SLICE_EDIT_SERVICE_BASCKET = "bascketSlice/editService";

export const REMOVE_SERVICE_BASCKET = "bascketSlice/removeService";
export const INCREASE_SERVICE_QUANTITY_BASCKET =
  "bascketSlice/increaseServiceQuantity";

  export const ADD_SELECTED_SERVICE = "bascketSlice/addSelectedService";
  export const REMOVE_SELECTED_SERVICE = "bascketSlice/removeSelectedService";