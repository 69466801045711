import { useEffect, useState } from "react";
import "./index.scss";
import Select from "react-select";
import { useSelector, useStore } from "react-redux";
import { ReactComponent as StoreReceiver } from "./StoreReceiver.svg";
import { ReactComponent as StoreSender } from "./StoreSender.svg";

import {
  INCREASE_QUANTITY,
  PIZZA_SLICE_ADD_PRODUCT,
  REMOVE_PRODUCT,
  RESET_LIST,
  SET_RECEIVER,
} from "../../store/slices/reducers/transactionDataSlice";
import transferStock from "../../services/post-transfer-stock";
import { toast } from "react-toastify";
import getStoreById from "../../services/get-store-by-id";
import { calculDoseInKg } from "../../utils";

const AlimentationForm = ({
  filteredOptions,
  storesOptions,
  selectedStore,
  setSelectedStore,
  storeId,
}: any) => {
  const store = useStore();

  const [storeDetails, setStoreDetails] = useState<any>();
  useEffect(() => {}, []);
  const productsState = useSelector(
    (state: Storage) => state?.entities?.transactionDataSlice?.products
  );

  const doResetList = () => {
    try {
      store.dispatch({
        type: RESET_LIST,
        payload: {},
      });
    } catch (error) {
      console.log(" redux error", error);
    }
  };
  const handleOptionStore = (option: any) => {
    setSelectedStore(option);

    try {
      store.dispatch({
        type: SET_RECEIVER,
        payload: { id: option?._id, name: option?.name },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const handleOptionSelectToStore = (option: any): void => {
    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_PRODUCT,
        payload: option,
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const doReset = () => {
    try {
      store.dispatch({
        type: RESET_LIST,
        payload: {},
      });
    } catch (error) {
      console.log("errror redux");
    }
  };



  const doRemoveProduct = (prod: any) => {
    try {
      store.dispatch({
        type: REMOVE_PRODUCT,
        payload: { prod },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };
  const updateQuantity = (prod: any, quantity: any) => {
    try {
      store.dispatch({
        type: INCREASE_QUANTITY,
        payload: { productId: prod?._id, quantity: quantity },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };

  const receiverState = useSelector(
    (state: Storage) => state?.entities?.transactionDataSlice?.receiver
  );


  const doTransferStock = () => {
    let listProd: any = [];
    productsState.map((prod: any) => {
      const newProd = { productId: prod?._id, quantity: prod?.quantity };
      listProd.push(newProd);
    });
    const bodyData = {
      senderStoreId: Boolean(storeId) ? storeId : "653291c6fea5eafb87c7cdd8",
      receiverStoreId: receiverState?.id,
      transfers: listProd,
      note: `transfer from Inventory to ${receiverState?.name} `,
    };
    transferStock(bodyData)
      .then((response: any) => {
        console.log("response", response);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          doResetList();
          toast.success("stock transfered successfuly !");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const doGetStoreById = (storeId: any) => {
    getStoreById(storeId)
      .then((response: any) => {
       
        if (response.data.success) {
          setStoreDetails(response.data.content[0]);
        }else{
          if (response.data.status === 500) {
            toast.error("server error !");
            return;
          }
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    if (Boolean(storeId)) {
      doGetStoreById(storeId);
    }
    return () => {
      doReset();
    };
  }, []);

  const colourStyles = {
    control: (styles : any) => ({
      ...styles,
      backgroundColor: 'white',
      borderColor: 'gray',
      borderWidth: 1,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'darkgray',
      },
    }),
    option: (styles : any, { isDisabled, isFocused, isSelected } : any) => ({
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#fff'
        : isFocused
        ? 'lightgray'
        : undefined,
      color: isDisabled
        ? '#000'
        : isSelected
        ? '#000'
        : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'darkblue' : 'gray'),
      },
    }),
    placeholder: (styles : any) => ({
      ...styles,
      color: 'gray',
      fontStyle: 'italic',
    }),
    singleValue: (styles : any) => ({
      ...styles,
      color: 'black',
    }),
    menu: (styles : any) => ({
      ...styles,
      backgroundColor: 'white',
      border: '1px solid lightgray',
    }),
    menuList: (styles : any) => ({
      ...styles,
      padding: 0,
    }),
    input: (styles : any) => ({
      ...styles,
      color: 'black',
    }),
    indicatorSeparator: (styles : any) => ({
      display: 'none',
    }),
    dropdownIndicator: (styles : any) => ({
      ...styles,
      color: 'gray',
      '&:hover': {
        color: 'black',
      },
    }),
  };

  return (
    <div className="alimentation-form-content">
      <div className="alimentaion-form-wrapper">
        <div className="alimentation-container">
          <div className="alimentation-form-left-side">
            <div className="alimentation-form-left-side-title">
              <p className="alimentation-form-left-side-title-txt">Produits</p>
            </div>
            <div className="input-alimentation-wrapper">
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="ex: thon"
                options={filteredOptions}
                value={filteredOptions}
                styles={colourStyles}
                onChange={handleOptionSelectToStore}
              />
            </div>
            <div className="alimentation-dosage-inputs">
              {productsState?.length > 0 ? (
                productsState?.map((prod: any, index: any) => (
                  <div key={index} className="input-dosage-item">
                    <div className="input-dosage-item-part-1">
                      <div className="prod-name-container">
                        <p className="prod-name-txt">
                          {prod?.Category?.name} : {prod?.name}{" "}
                          {calculDoseInKg(prod)} kg
                        </p>
                      </div>
                      <div className="delete-wrapper">
                        <i
                          onClick={() => doRemoveProduct(prod)}
                          className="bx bx-x"
                          style={{ fontSize: 28, color: "#d55" }}
                        ></i>
                      </div>
                    </div>
                    <div className="input-dosage-item-part-2">
                      <div className="input-wrapper">
                        <input
                          className="input-weight"
                          value={prod?.quantity}
                        />
                        <div className="unity-wrapper"></div>
                      </div>
                      <div className="increment-decrement-btn-wrapper">
                        <button
                          className="increment-btn"
                          onClick={() => updateQuantity(prod, 1)}
                        >
                          <i
                            className="bx bx-chevron-up"
                            style={{ fontSize: 28, color: "#159B7B" }}
                          ></i>
                        </button>
                        <button
                          disabled={prod?.quantity === 1 ? true : false}
                          className="decrement-btn"
                          onClick={() => updateQuantity(prod, -1)}
                        >
                          <i
                            className="bx bx-chevron-down"
                            style={{ fontSize: 28, color: "#DF9F1C" }}
                          ></i>
                        </button>
                      </div>
                    </div>
                    <div className="input-dosage-item-part-3">
                      <p className="doses-txt">{calculDoseInKg(prod)} dose</p>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            <div className="alimentation-distination-wrapper">
              <div className="title-wrapper">
                <p className="title-txt">
                  Distination {"("} store {")"}
                </p>
              </div>
              <div>
                <Select
                  placeholder="ex: Store 1"
                  options={storesOptions}
                  value={selectedStore}
                  // defaultInputValue="Store"
                  onChange={handleOptionStore}
                />
              </div>
            </div>
          </div>
          <div className="alimentation-form-right-side">
            <div className="resume-title-wrapper">
              <p className="resume-txt">Résumé</p>
              <div className="horizontale-line" />
            </div>
            <div className="details-txt-wrapper">
              {productsState?.length > 0 ? (
                productsState?.map((prod: any, index: any) => (
                  <p className="details-line-txt">
                    {prod?.quantity} X {calculDoseInKg(prod)} Kg{" "}
                    {prod?.Category?.name} : {prod?.name}
                  </p>
                ))
              ) : (
                <></>
              )}
            </div>
            <div className="store-images-wrapper">
              <div className="sender-image-wrapper">
                <StoreSender />
                <div
                className="store-name-wrapper"
                >
                  <p>
                    {" "}
                    {Boolean(storeId)
                      ? storeDetails?.owner?.name
                      : "Inventaire"}{" "}
                  </p>
                </div>
              </div>
              <div className="flesh-image-wrapper">
                <img src="./../../assets/flesh.png" alt="flesh" />
              </div>
              <div className="reciver-image-wrapper">
                <StoreReceiver />

                <div
                  style={{
                    width: 100,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p> {receiverState?.name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="alimentation-footer">
        <div className="buttons-wrapper">
          <button className="cancel-button" onClick={() => doReset()}>
            Annuler
          </button>
          <button className="confirm-button" onClick={() => doTransferStock()}>
            Envoyer
          </button>
        </div>
      </div>
    </div>
  );
};
export default AlimentationForm;
