import React, { useState } from "react";
import Lottie from "react-lottie";
import { useSelector, useStore } from "react-redux";
import { toast } from "react-toastify";
import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../../assets/lottie-animations/animationOptions";
import addPurchase from "../../../services/Add-purchace";
import { RESET_LIST_BASCKET } from "../../../store/slices/reducers/bascketSlice";
import "./index.scss";
function ModalConfirmOrder({ closeModal }: any) {
  const [loading, setLoading] = useState(false);
  
  let basketOrderProducts = useSelector(
    (state: any) => state?.entities?.bascketSliceOrder?.products
  );

  const store = useStore();
  const resetList = () => {
    try {
      store.dispatch({
        type: RESET_LIST_BASCKET,
        payload: {},
      });
    } catch (error) {
      console.log(" redux error", error);
    }
  };

  const doAddPurchace = () => {
    setLoading(true);
    const bodyData = {
      products: basketOrderProducts.map((product: any) => ({
        id: product._id,
        quantity: product.quantity,
      })),
    };
    addPurchase(bodyData)
      .then((response) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast?.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          toast.success("le bon de commande a été ajouté avec succès");
          resetList()
          closeModal();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  return (
    <>
      {loading ? (
        <Lottie options={defaultOptionsLoading} height={"60%"} width={"30%"} />
      ) : (
        <div className="delete-permission-container">
          <div className="delete-permission-body">
            <div className="product-info">
              <h2>Êtes Vous sure de vouloir passer la commande ?</h2>
            </div>
          </div>
          <div className="delete-permission-footer">
            <button className="delete-cancel-btn" onClick={() => closeModal()}>
              Non
            </button>
            <button
              className="delete-confirm-btn"
              onClick={() => doAddPurchace()}
            >
              Oui
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalConfirmOrder;
