// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cards-content-list {
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  justify-content: flex-start;
  min-height: 60vh;
  overflow: auto;
  align-items: flex-start; /* Add this line */
  width: 100%;
}

@media (max-width: 800px) {
  .Cards-content-list {
    justify-content: space-around;
    flex-basis: 20%;
    align-items: flex-start; /* Add this line */
    min-height: 60vh;
  }
}
@media (max-width: 380px) {
  .Cards-content-list {
    justify-content: center;
    align-items: flex-start; /* Add this line */
  }
}
@media (max-width: 500px) {
  .Cards-content-list > * {
    flex-basis: 20%;
  }
}
@media (max-width: 400px) {
  .Cards-content-list > * {
    flex-basis: 25%;
  }
}
@media (max-width: 320px) {
  .Cards-content-list > * {
    flex-basis: 33.33%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ListCard/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,oBAAA;EACA,2BAAA;EACA,gBAAA;EACA,cAAA;EACA,uBAAA,EAAA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE;IACE,6BAAA;IACA,eAAA;IACA,uBAAA,EAAA,kBAAA;IACA,gBAAA;EACF;AACF;AAGA;EACE;IACE,uBAAA;IACA,uBAAA,EAAA,kBAAA;EADF;AACF;AAIA;EACE;IACE,eAAA;EAFF;AACF;AAKA;EACE;IACE,eAAA;EAHF;AACF;AAMA;EACE;IACE,kBAAA;EAJF;AACF","sourcesContent":[".Cards-content-list {\n  display: flex;\n  flex-wrap: wrap;\n  align-content: start;\n  justify-content: flex-start;\n  min-height: 60vh;\n  overflow: auto;\n  align-items: flex-start; /* Add this line */\n  width: 100%;\n}\n\n@media (max-width: 800px) {\n  .Cards-content-list {\n    justify-content: space-around;\n    flex-basis: 20%;\n    align-items: flex-start; /* Add this line */\n    min-height: 60vh;\n\n  }\n}\n\n@media (max-width: 380px) {\n  .Cards-content-list {\n    justify-content: center;\n    align-items: flex-start; /* Add this line */\n  }\n}\n\n@media (max-width: 500px) {\n  .Cards-content-list > * {\n    flex-basis: 20%;\n  }\n}\n\n@media (max-width: 400px) {\n  .Cards-content-list > * {\n    flex-basis: 25%;\n  }\n}\n\n@media (max-width: 320px) {\n  .Cards-content-list > * {\n    flex-basis: 33.33%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
