import { useEffect, useState } from "react";
import SideBar from "../../SideBar";
import "./index.scss";
import HeaderComponent from "../../Header";
import NotificationsList from "../../NotificationsList";

const NotificationScreen = () => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="row">
      <div
        className={`col-12 col-md-3 ${showMenu ? "show-menu" : ""}`}
        style={{
          background: `linear-gradient(184deg, #F8FFFD -2.42%, #FFF7F7 -2.41%, #F9FFFA 44.7%, #EEE 100.39%)`,
        }}
      >
        <SideBar routePage={""} />
      </div>
      <div className="col-12 col-md-9">
        <div className="content-products-and-bar">
          <HeaderComponent title="Notifications" />
          <div className="container-notif">
            <NotificationsList />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotificationScreen;
