import React, { useState } from "react";
import Lottie from "react-lottie";
import { useSelector, useStore } from "react-redux";
import { defaultOptionsEmpty } from "../../assets/lottie-animations/animationOptions";
import {
  INCREASE_QUANTITY_BASCKET_ORDER,
  REMOVE_PRODUCT_BASCKET_ORDER,
  RESET_LIST_BASCKET_ORDER,
} from "../../store/slices/reducers/basketSliceOrder";
import Modal from "react-modal";

import { calculateTotal, calculDoseInKg } from "../../utils";
import HeaderComponent from "../Header";
import Layer from "../Layer";
import "./style/basket.scss";
import ModalConfirmOrder from "../modals/ModalConfirmOrder";
const customStylesEditQte = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "33%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
function BasketScreen() {
  const [query, setQuery] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let basketOrderProducts = useSelector(
    (state: any) => state?.entities?.bascketSliceOrder?.products
  );
  const store = useStore();

  const doRemoveProduct = (prod: any) => {
    console.log("prod 000000", prod);

    try {
      store.dispatch({
        type: REMOVE_PRODUCT_BASCKET_ORDER,
        payload: { prod },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };
  const doResetBasket = () => {
    try {
      store.dispatch({
        type: RESET_LIST_BASCKET_ORDER,
        payload: {},
      });
    } catch (error) {
      console.log(" redux error", error);
    }
  };

  const updateQuantity = (prod: any, quantity: any) => {
    console.log(" prod?._id", prod?._id);

    try {
      store.dispatch({
        type: INCREASE_QUANTITY_BASCKET_ORDER,
        payload: { productId: prod?._id, quantity: quantity },
      });
    } catch (error) {
      console.log("errror redux");
    }
  };
  const passOrder = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  

  return (
    <Layer title={"basket"}>
      <HeaderComponent title="Panier" setQuery={setQuery} />

      <div className="basket-high-content">
        <div className="basket-content">
          {basketOrderProducts?.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Qte</th>
                  <th>Pt</th>
                </tr>
              </thead>
              <tbody>
                {basketOrderProducts?.map((prod: any, index: any) => (
                  <tr key={index}>
                    <td style={{ width: "55vw" }}>
                      <div className="prod-name-wrapper">
                        <span>
                          {prod?.Category?.name} {prod?.name} {prod?.portion}{" "}
                          {prod?.unit}
                        </span>
                        <span>{calculDoseInKg(prod)} doses</span>
                      </div>
                    </td>
                    <td>
                      <div className="input-wrapper">
                        <input
                          className="input-weight"
                          value={prod?.quantity}
                          readOnly
                        />
                        <div className="increment-decrement-btn-wrapper">
                          <button
                            className="increment-btn"
                            onClick={() => updateQuantity(prod, 1)}
                          >
                            <i className="bx bx-chevron-up"></i>
                          </button>
                          <button
                            disabled={prod?.quantity === 1}
                            className="decrement-btn"
                            onClick={() => updateQuantity(prod, -1)}
                          >
                            <i className="bx bx-chevron-down"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="total-price-wrapper">
                        <span>{prod?.cost * prod?.quantity}  DT</span>
                      </div>
                    </td>
                    <td>
                      <i
                        onClick={() => doRemoveProduct(prod)}
                        className="bx bx-x"
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>
              <Lottie
                options={defaultOptionsEmpty}
                height={"60%"}
                width={"30%"}
              />
            </div>
          )}
        </div>
        {basketOrderProducts?.length ? (
          <div className="bottom-tab-container">
            <div className="total-container">
              <span>Total</span>
              <span style={{ marginLeft: 25 }}>{calculateTotal(basketOrderProducts)} DT</span>
            </div>
            <div className="confirm-order-container">
              {basketOrderProducts?.length > 0 ? (
                <button className="cancel-btn" onClick={() => doResetBasket()}>
                  Annuler
                </button>
              ) : (
                <></>
              )}
              <button className="order-confirm-btn" onClick={() => passOrder()}>
                Passer la commande
              </button>
            </div>
          </div>
        ) : null}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesEditQte}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalConfirmOrder closeModal={closeModal} />
      </Modal>
    </Layer>
  );
}

export default BasketScreen;
