import "./index.scss";

import CardStore from "../cardStore";

type ListStoresProps = {
  openModal: Function;
  listStores: any;
};

function ListStores({ openModal, listStores }: ListStoresProps): JSX.Element {
  return (
    <div className="list-stores-wrapper">
      {ListStores?.length > 0 ? (
        listStores?.filter((store: any) => store?.name !== "General Stock")?.map((store: any, index: any) => (
          <div
            style={{ width: "100%" }}
            onClick={() => openModal(store)}
            key={index}
          >
            <CardStore store={store} />
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

export default ListStores;
