import NotifAchatItem from "../NotifAchatItem";
import NotifMissProduct from "../NotifMissProduct";
import NotifRejectItem from "../NotifRejectItem";
import "./index.scss";
const NotificationsList = () => {
  return (
    <div className="content">
      <div className="notif-header">
        <p>18/07/2024</p>
      </div>
      <div className="notification-container">
        <div>
          <NotifAchatItem />
        </div>
        <div>
          <NotifMissProduct />
        </div>
        <div>
          <NotifRejectItem />
        </div>
      </div>
    </div>
  );
};
export default NotificationsList;
