const initialState = { products: [] };

const bascketSliceOrder = {
  initialState,
  reducers: {
    addProduct(state: any, action: any) {
      console.log("action.payload", action.payload);

      const existingProduct = state.products.find(
        (product: any) => product._id === action.payload._id
      );

      if (existingProduct) {
        // If the product exists, increment the quantity
        existingProduct.quantity += action.payload.quantity;
      } else {
        // If the product does not exist, add it to the state
        state.products.push(action.payload);
      }
    },

    removeProduct(state: any, action: any) {
      console.log("action.payload. --------------------", action.payload);

      state.products = state.products.filter(
        (product: any) => product?._id !== action.payload?.prod?._id
      );
    },

    increaseQuantity(state: any, action: any) {
      const productIdToIncrease = action.payload?.productId;
      const quantityToIncrease = action.payload?.quantity;
      console.log("quantityToIncrease", quantityToIncrease);

      if (productIdToIncrease && quantityToIncrease) {
        const productIndex = state.products.findIndex(
          (product: any) => product._id === productIdToIncrease
        );
        console.log("productIndex", productIndex);

        if (productIndex !== -1) {
          state.products[productIndex].quantity += quantityToIncrease;
        }
      }
    },

    resetList(state: any, action: any) {
      return { ...initialState };
    },
    setQuantity(state: any, action: any) {
      const productIndex = state.products.findIndex(
        (product: any) => product._id === action.payload?.productId
      );

      if (productIndex !== -1) {
        // Directly set the quantity from input
        state.products[productIndex].quantity = action.payload?.quantity;
      }
    },
  },
};

export default bascketSliceOrder;

export const PIZZA_SLICE_ADD_PRODUCT_BASCKET_ORDER =
  "bascketSliceOrder/addProduct";
export const RESET_LIST_BASCKET_ORDER = "bascketSliceOrder/resetList";
export const REMOVE_PRODUCT_BASCKET_ORDER = "bascketSliceOrder/removeProduct";
export const INCREASE_QUANTITY_BASCKET_ORDER =
  "bascketSliceOrder/increaseQuantity";
  export const SET_QUANTITY_BASCKET_ORDER = "bascketSliceOrder/setQuantity";
