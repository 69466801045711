import "./index.scss";
import { useState, useEffect } from "react";

import SideBar from "../../SideBar";
import ListStores from "../../ListStores";
import HeaderComponent from "../../Header";
import { useNavigate } from "react-router-dom";
import { store } from "../../../utils/types/store";
import getAllStores from "../../../services/get-all-stores";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../../assets/lottie-animations/animationOptions";
import Layer from "../../Layer";

function StoresScreen(): JSX.Element {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const [listStore, setListStore] = useState<store[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  function openModal(store: any) {
    navigate(`/store-details/${store?._id}`);
  }

  function doGetListStores() {
    setLoading(true);
    getAllStores()
      .then((response: any) => {
        setLoading(false);

        if (response.data.success) {
          setListStore(response.data.content);
        } else {
          if (response.data.status === 500) {
            console.log("server error");
            return;
          }
          if (!response.data.success) {
            console.log("error" + response.data.content);
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
        console.log("error :", error);
      });
  }

  useEffect(() => {
    doGetListStores();
  }, []);

  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layer title={"stores"}>
      <HeaderComponent title="Stores" />

      {loading ? (
        <div className="loading-content">
          <Lottie
            options={defaultOptionsLoading}
            height={"60%"}
            width={"30%"}
          />
        </div>
      ) : listStore.length > 0 ? (
        <div className="products-container-ss">
          <ListStores openModal={openModal} listStores={listStore} />
        </div>
      ) : (
        <div className="loading-content">
          <Lottie options={defaultOptionsEmpty} height={"60%"} width={"30%"} />
        </div>
      )}
    </Layer>
  );
}

export default StoresScreen;
