import React, { Children, useEffect, useState } from "react";
import { capitalizeFirstLetter, useWindowDimensions } from "../../utils";
import HeaderComponent from "../Header";
import Menu from "../Menu";
import SideBar from "../SideBar";
import "./index.scss";

function Layer({ children, title = "" }: any) {
  const [showMenu, setShowMenu] = useState(false);
  const { height, width } = useWindowDimensions();
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      // setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("show ", showMenu);

  return (
    <div className="high-content">
      <div
        className={
          width > 768
            ? `col-12 col-md-3 ${showMenu ? "show-menu" : ""}`
            : "high-content"
        }
        style={{
          background: `linear-gradient(184deg, #F8FFFD -2.42%, #FFF7F7 -2.41%, #F9FFFA 44.7%, #EEE 100.39%)`,
        }}
      >
        {width > 768 ? (
          <SideBar routePage={title} />
        ) : (
          <i
            className="bx bx-menu"
            style={{ fontSize: 26 }}
            onClick={toggleMenu}
          ></i>
        )}
        {width <= 768 && showMenu ? (
          <Menu
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            toggleMenu={toggleMenu}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="col-12 col-md-9">
        {width < 768 ? (
          <i
            className="bx bx-menu"
            style={{ fontSize: 26 }}
            onClick={toggleMenu}
          ></i>
        ) : (
          <></>
        )}
        <div className={showMenu ? "content-blur" : "content"}>{children}</div>
      </div>
    </div>
  );
}

export default Layer;
