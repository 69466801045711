import "./index.scss";

import { useState, useEffect } from "react";

import Modal from "react-modal";
import Lottie from "react-lottie";

import ListCard from "../../ListCard";
import SideBar from "../../SideBar";
import IngrediantUpdateModal from "../../modals/IngrediantUpdateModal";
import HeaderComponent from "../../Header";
import ModalAddCategory from "../../modals/ModalAddCategory";
import getAllCategories from "../../../services/get-all-categories";
import { toast } from "react-toastify";
import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../../assets/lottie-animations/animationOptions";
import Layer from "../../Layer";
import updateCategory from "../../../services/update-category";

const customStylesAdd = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "35vh",
    width: "35%",
    minWidth: "350px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function ProductsScreen(): JSX.Element {
  const [showMenu, setShowMenu] = useState(false);
  const [modalAddIsOpen, setAddIsOpen] = useState(false);
  const [listCategories, setListCategories] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<any>(null);

  function closeAddModal() {
    setAddIsOpen(false);
    setEditMode(false);
    setCategoryToEdit(null);
  }

  const doGetAllCategories = () => {
    setLoading(true);
    getAllCategories()
      .then((response: any) => {
        setLoading(false);

        if (response.data.success) {
          setListCategories(response.data.content);
        } else {
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
          if (response.data.status === 500) {
            toast.error("internal server error !");
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    doGetAllCategories();
  }, []);

  const doEditCategory = (category: any) => {
    setAddIsOpen(true);
    setEditMode(true);
    setCategoryToEdit(category);
  };

  return (
    <>
      <Layer title={"products"} setAddIsOpen={setAddIsOpen}>
        <HeaderComponent title="Catégories" setAddIsOpen={setAddIsOpen} />

        <div className="container-prods">
          {loading ? (
            <div className="animation-wrapper">
              <Lottie
                options={defaultOptionsLoading}
                height={"60%"}
                width={"30%"}
              />
            </div>
          ) : listCategories?.length > 0 ? (
            <ListCard
              listCategories={listCategories}
              doEditCategory={doEditCategory}
            />
          ) : (
            <div className="animation-wrapper">
              <Lottie
                options={defaultOptionsEmpty}
                height={"60%"}
                width={"30%"}
              />
            </div>
          )}
        </div>
      </Layer>

      <Modal
        isOpen={modalAddIsOpen}
        onRequestClose={closeAddModal}
        style={customStylesAdd}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ModalAddCategory
          closeAddModal={closeAddModal}
          doGetAllCategories={doGetAllCategories}
          editMode={editMode}
          categoryToEdit={categoryToEdit}
        />
      </Modal>
    </>
  );
}

export default ProductsScreen;
