import React from "react";
import Select from "react-select"; // Import react-select
import "./index.scss"; // Import your SCSS file
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_SELECTED_SERVICE,
  REMOVE_SELECTED_SERVICE,
  REMOVE_SERVICE_BASCKET,
} from "../../store/slices/reducers/bascketSlice";
const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    width: "300px", // Set the width to 60%
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    width: "300px", // Set the width to 60%
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#e2e2e2" : "white",
    color: "black",
  }),
};
const ServiceList = ({
  setDisplayForm,
  listServices,
  setServiceToEdit,
  setEditMode,
  editMode,
  setIndexToEdit,
}: any) => {
  const dispatch = useDispatch(); // Use the useDispatch hook
  const services = useSelector(
    (state: any) => state?.entities?.bascketSlice?.services
  );

  const selectedServices = useSelector(
    (state: Storage) => state?.entities?.bascketSlice?.selectedServices
  );

  const handleRemoveSelectedService = (index: any) => {
    try {
      dispatch({ type: REMOVE_SELECTED_SERVICE, payload: { index: index } });
      console.log(`Service at index ${index} has been successfully deleted.`);
    } catch (error) {
      console.error(`Failed to delete service at index ${index}:`, error);
    }
  };

  const handleDelete = (index: number) => {
    try {
      dispatch({ type: REMOVE_SERVICE_BASCKET, payload: { index } });
      console.log(`Service at index ${index} has been successfully deleted.`);
    } catch (error) {
      console.error(`Failed to delete service at index ${index}:`, error);
    }
  };
  console.log("list services", listServices, selectedServices);
  const handleSelectService = (service: any) => {
    console.log("in func", service);
    const serv = {
      id: service?.value,
      name: service?.label,
      price: service?.price,
    };

    try {
      dispatch({ type: ADD_SELECTED_SERVICE, payload: serv });
    } catch (error) {
      console.log(error);
    }
  };

  let options = listServices
    .filter(
      (service: any) =>
        !selectedServices?.some(
          (selected: any) => String(selected?.id) === String(service?._id)
        )
    )
    ?.map((service: any) => ({
      value: service._id,
      label: service.name,
      price: service.price,
    }));

  const handleEdit = (service: any, index: any) => {
    setServiceToEdit(service);
    setIndexToEdit(index);
    setEditMode(true);
    setDisplayForm(true);
  };

  return (
    <div className="container">
      {/* Input Section */}
      <div className="input-wrapper">
        {/* React Select component */}
        <Select
          styles={customStyles}
          options={options}
          onChange={(selectedOption: any) => {
            const selectedService = listServices.find(
              (service: any) => service.id === selectedOption.value
            );
            handleSelectService(selectedOption);
          }}
          placeholder="Select a service"
        />
        <div className="search-section">
          <span>ou</span>
          <button
            className="new-service-button"
            onClick={() => setDisplayForm(true)}
          >
            Nouveau Service
          </button>
        </div>
      </div>

      {/* Service List */}
      <div className="table-wrapper">
        {services?.length > 0 ? (
          services.map((service: any, index: number) => (
            <div key={index} className="service-row">
              <div className="price">{service?.price}dt</div>
              <div className="service-name">{service.name}</div>
              <div className="action-buttons">
                <button onClick={() => handleEdit(service, index)}>✏️</button>
                <button onClick={() => handleDelete(index)}>🗑️</button>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
        {selectedServices?.length > 0 ? (
          selectedServices.map((service: any, index: number) => (
            <div key={index} className="service-row">
              <div className="price">{service?.price}dt</div>
              <div className="service-name">{service?.name}</div>
              <div className="action-buttons">
                {/* <button>✏️</button> */}
                <button onClick={() => handleRemoveSelectedService(index)}>
                  🗑️
                </button>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ServiceList;
