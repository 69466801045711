// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cards-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
}

@media (max-width: 800px) {
  .Cards-content {
    justify-content: space-around;
    flex-basis: 20%;
  }
}
@media (max-width: 380px) {
  .Cards-content {
    justify-content: center;
  }
}
@media (max-width: 500px) {
  .Cards-content > * {
    flex-basis: 20%;
  }
}
@media (max-width: 400px) {
  .Cards-content > * {
    flex-basis: 25%;
  }
}
@media (max-width: 320px) {
  .Cards-content > * {
    flex-basis: 33.33%;
  }
}
.list-stores-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  justify-content: flex-start;
  height: 70vh;
  overflow: auto;
  align-items: flex-start; /* Add this line */
  width: 100%;
}

@media (max-width: 568px) {
  .list-stores-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    justify-content: center;
    height: 70vh;
    overflow: auto;
    align-items: center; /* Add this line */
    align-self: center;
    padding: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ListStores/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,8BAAA;EACA,cAAA;AACJ;;AAGE;EACE;IACE,6BAAA;IACA,eAAA;EAAJ;AACF;AAGE;EACE;IACE,uBAAA;EADJ;AACF;AAIE;EACE;IACE,eAAA;EAFJ;AACF;AAKE;EACE;IACE,eAAA;EAHJ;AACF;AAME;EACE;IACE,kBAAA;EAJJ;AACF;AAOE;EACE,aAAA;EACA,eAAA;EACA,oBAAA;EACA,2BAAA;EACA,YAAA;EACA,cAAA;EACA,uBAAA,EAAA,kBAAA;EACA,WAAA;AALJ;;AAOE;EACE;IACE,aAAA;IACA,eAAA;IACA,oBAAA;IACA,uBAAA;IACA,YAAA;IACA,cAAA;IACA,mBAAA,EAAA,kBAAA;IAGA,kBAAA;IACA,aAAA;EANJ;AACF","sourcesContent":[".Cards-content {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    overflow: auto;\n  \n  }\n  \n  @media (max-width: 800px) {\n    .Cards-content {\n      justify-content: space-around;\n      flex-basis: 20%;\n    }\n  }\n  \n  @media (max-width: 380px) {\n    .Cards-content {\n      justify-content: center;\n    }\n  }\n  \n  @media (max-width: 500px) {\n    .Cards-content > * {\n      flex-basis: 20%;\n    }\n  }\n  \n  @media (max-width: 400px) {\n    .Cards-content > * {\n      flex-basis: 25%;\n    }\n  }\n  \n  @media (max-width: 320px) {\n    .Cards-content > * {\n      flex-basis: 33.33%;\n    }\n  }\n  \n  .list-stores-wrapper{\n    display: flex;\n    flex-wrap: wrap;\n    align-content: start;\n    justify-content: flex-start;\n    height: 70vh;\n    overflow: auto;\n    align-items: flex-start; /* Add this line */\n    width: 100%;\n  }\n  @media (max-width : 568px) {\n    .list-stores-wrapper{\n      display: flex;\n      flex-wrap: wrap;\n      align-content: start;\n      justify-content: center;\n      height: 70vh;\n      overflow: auto;\n      align-items: center; /* Add this line */\n      // width: 100vw;\n      // background-color: red;\n      align-self: center;\n      padding: 10px;\n    }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
