import { toast } from "react-toastify";
import "./index.scss";
import { Formik } from "formik";
import EditProduct from "../../../services/put-product";
import deleteProduct from "../../../services/delete-product";
import addProvider from "../../../services/post-add-provider";
import * as Yup from "yup";
import deleteProvider from "../../../services/delete-provider";
import { useState } from "react";

const dataState = {
  fullname: "",
  phoneNumber: "",
  email: "",
  description: "",
};
const validationSchema = Yup.object().shape({
  fullname: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must be digits only")
    .min(8, "Phone number must be at least 8")
    .max(8, "Phone number must be at most 8"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),
  description: Yup.string()
    .required("Description is required")
    .min(10, "Description must be at least 10 characters")
    .max(300, "Description must be at most 300 characters"),
});
const ModalProvider = ({
  closeAddProviderModal = () => {},
  categoryId,
  doGetProductsByCategoryStore,
  editMode,
  productId,
  productToEditData,
  setDeletePermission,
  deletePermission,
  storeId,
  doGetAllProviders,
  doGetProviders,
  doUpdateProvider,
}: any) => {
  const [loading, setLoading] = useState(false);
  const doAddProvider = (data: any) => {
    setLoading(true);
    const bodyData = {
      fullname: data?.fullname,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      description: data?.description,
    };
    addProvider(bodyData)
      .then((response) => {
        setLoading(false);

        if (response.data.success) {
          doGetProviders(1, 5);
          closeAddProviderModal();
          toast.success("Provider added successfuly !");
        } else {
          if (response.data.status === 500) {
            toast.error("server error !");
            return;
          }
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  const doEditProvider = (values: any) => {
    setLoading(true);

    EditProduct(values, productId)
      .then((response) => {
        setLoading(false);

        if (response.data.status === 500) {
          toast.error("server error !");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success("product updated successfuly !");
          doGetProductsByCategoryStore();
          closeAddProviderModal();
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  const doDeleteProduct = () => {
    console.log("tesss");

    setDeletePermission(true);
  };

  const doDeleteProductAction = (productId: any, storeId: any) => {
    deleteProvider(productId)
      .then((response) => {
        if (response.data.success) {
          doGetProviders(1, 5);
          toast.success("provider deleted successfuly !");

          closeAddProviderModal();
        } else {
          if (response.data.status === 500) {
            toast.error("server error !");
            return;
          }
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  console.log(productId);
  return (
    <>
      {deletePermission ? (
        <div className="delete-permission-container">
          <div className="delete-permission-body">
            <div className="product-info">
              <span>Êtes Vous sure de Supprimer le Fournisseur</span>
              <span className="prod-name-txt">{productToEditData?.name}</span>
            </div>
          </div>
          <div className="delete-permission-footer">
            <button
              className="delete-cancel-btn"
              onClick={() => closeAddProviderModal()}
            >
              Non
            </button>
            <button
              className="delete-confirm-btn"
              onClick={() => doDeleteProductAction(productId, storeId)}
            >
              Oui
            </button>
          </div>
        </div>
      ) : (
        <div className="modal-add-prod-container">
          <div className="modal-add-prod-header">
            <p className="new-prod-txt">
              {" "}
              {editMode && Boolean(productId)
                ? "Informations de Fournisseur "
                : "Nouveau Fournisseur"}{" "}
            </p>
            <i
              onClick={() => closeAddProviderModal()}
              className="bx bx-x"
              style={{ fontSize: 28, fontWeight: "400", color: "#000" }}
            ></i>
          </div>
          <div className="modal-add-prod-body">
            <Formik
              initialValues={Boolean(productId) ? productToEditData : dataState}
              validationSchema={validationSchema}
              onSubmit={(values: any, { setSubmitting }) => {
                setTimeout(() => {
                  if (Boolean(productId) && editMode) {
                    doUpdateProvider(productId, values);
                  } else {
                    doAddProvider(values);
                  }
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }: any) => (
                <form className="form-container" onSubmit={handleSubmit}>
                  <div className="first-row-container">
                    <div className="input-container">
                      <input
                        className="input-name-style"
                        type="text"
                        name="fullname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.fullname}
                        placeholder="Nom"
                      />

                      <p
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 400,
                          width: "100%",
                          textAlign: "start",
                          marginLeft: 8,
                        }}
                      >
                        {" "}
                        {errors.fullname && touched.fullname && errors.fullname}
                      </p>
                    </div>
                    <div className="input-container">
                      <input
                        className="input-type-style"
                        type="number"
                        name="phoneNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.phoneNumber}
                        placeholder="Tél"
                      />

                      <p
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 400,
                          width: "100%",
                          textAlign: "start",
                          marginLeft: 8,
                        }}
                      >
                        {" "}
                        {errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber}
                      </p>
                    </div>
                  </div>
                  <div className="second-row-container">
                    <div className="input-container">
                      <input
                        className="input-price-style"
                        type="text"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.email}
                        placeholder="Email"
                      />

                      <p
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 400,
                          width: "100%",
                          textAlign: "start",
                          marginLeft: 8,
                        }}
                      >
                        {" "}
                        {errors.email && touched.email && errors.email}
                      </p>
                    </div>
                    <div className="input-container">
                      <input
                        className="input-price-style"
                        type="text"
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.description}
                        placeholder="Description"
                      />

                      <p
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 400,
                          width: "100%",
                          textAlign: "start",
                          marginLeft: 8,
                        }}
                      >
                        {" "}
                        {errors.description &&
                          touched.description &&
                          errors.description}
                      </p>
                    </div>
                  </div>
                  {editMode && Boolean(productId) ? (
                    <div className="modal-add-prod-footer-edit-mode">
                      <div className="delete-btn-container">
                        <button
                          className="delete-btn"
                          onClick={() => doDeleteProduct()}
                        >
                          Supprimer
                        </button>
                      </div>
                      <div className="cancel-and-edit-btns-container">
                        <button
                          className="cancel-btn"
                          onClick={() => closeAddProviderModal()}
                        >
                          Annuler
                        </button>
                        <button className="confirm-btn">Confirmer</button>
                      </div>
                    </div>
                  ) : (
                    <div className="modal-add-prod-footer">
                      <button
                        className="modal-add-prod-btn-cancel-style"
                        onClick={() => closeAddProviderModal()}
                      >
                        Annuler
                      </button>
                      <button
                        className="modal-add-prod-btn-add-style"
                        type="submit"
                        disabled={loading}
                      >
                        Confirmer
                      </button>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};
export default ModalProvider;
