import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import setExpirationDate from '../../../services/put-expiration-date';
import './index.scss';

function ExperitionDate({ product, orderId, onClose , doGetPurchaceById }: any) {
  const [startDate, setStartDate] = useState<any>(new Date());
  const todayDate = new Date();
  const  [loading , setLoading] = useState(false)
 
  const doSetExpirationDate = () => {
    if (!startDate || startDate < todayDate) {
      toast.warning('Date invalide!');
      return;
    }
    setLoading(true)

    const data = {
      expirationDate: startDate,
    };

    setExpirationDate(orderId, product?._id, data)
      .then((response) => {
        setLoading(false)

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          toast.success('Date d\'expiration mise à jour!');
          doGetPurchaceById()
          onClose();        }
      
      })
      .catch((error) => {
        setLoading(false)

        toast.error('Erreur lors de la mise à jour de la date.');
      });
  };
  useEffect(() => {
     setStartDate(product?.expirationDate)
  } , [])

  return (
    <div className="modal-container">
      <div className="modal-header">
        <h2>Actualisation de la date d'expiration</h2>
        <span className="close-icon" onClick={onClose}>&times;</span>
      </div>
      <div className="item-info">
        <span>Item: <span className="item-name">{product?.name}</span></span>
      </div>
      <div className="expiration-date-section">
        <label htmlFor="expiration-date">Date d'expiration</label>
        <div className="expiration-date-input">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            className="date-picker-input"
          />
          <i className="calendar-icon bx bx-calendar"></i>
        </div>
      </div>
      <div className="modal-buttons">
        <button className="cancel-button" onClick={onClose}>Annuler</button>
        <button className="confirm-button" onClick={doSetExpirationDate}>Confirmer</button>
      </div>
    </div>
  );
}

export default ExperitionDate;
