import { useNavigate } from "react-router-dom";
import "./index.scss";
const NotifAchatItem = () => {
  const navigate = useNavigate();
  return (
    <div className="one-item-notif-content">
      <div className="one-item-notif-p-1">
        <div className="img-store-container">
          <img src="./../../assets/greenStore.png" alt="greenStore" />
        </div>
        <div className="achat-txt-num-container">
          <p className="achat-txt">Achat n° 14001477</p>
        </div>
      </div>
      <div className="one-item-notif-p-2">
        <div className="txt-info-container">
          <p className="txt-info">Votre Stock a été mis à jour.</p>
        </div>
        <div className="txt-value-container">
          <p className="txt-value">10 produits en été édités</p>
        </div>
      </div>
      <div
        className="one-item-notif-p-3"
        onClick={() => navigate("/notification-details/111")}
      >
        <p className="txt-consult">Consulter</p>
        <i
          className="bx bx-right-arrow-alt"
          style={{ fontSize: 40, color: "#159B7B", marginLeft: 10 }}
        ></i>
      </div>
    </div>
  );
};
export default NotifAchatItem;
