import "./index.scss";
const NotifMissProduct = () => {
  return (
    <div className="one-item-notif-content-m">
      <div className="one-item-notif-p-1-m">
        <div className="img-store-container-m">
          <img src="./../../assets/orangeStore.png" alt="greenStore" />
          <p style={{ color: "#EF834C", fontSize: 20, fontWeight: 700 }}>!</p>
        </div>
      </div>
      <div className="one-item-notif-p-2-m">
        <div className="txt-info-container-m">
          <p className="txt-info-m">
            Le store X nécessite une alimentation au niveau de quelques produits
            .
          </p>
        </div>
      </div>
      <div className="one-item-notif-p-3-m">
        <p className="txt-consult-m">Voir la liste</p>
        <i
          className="bx bx-right-arrow-alt"
          style={{ fontSize: 40, color: "#EF834C", marginLeft: 10 }}
        ></i>
      </div>
    </div>
  );
};
export default NotifMissProduct;
