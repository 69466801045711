import React, { useEffect, useState } from "react";
import "./indexlst.scss";
import EditQuantityModal from "../modals/EditQuantityModal";
import Modal from "react-modal";
import ModalHistoryProduct from "../modals/ModalHistoryProduct";
import { PIZZA_SLICE_ADD_PRODUCT } from "../../store/slices/reducers/transactionDataSlice";
import { useStore } from "react-redux";
import { PIZZA_SLICE_ADD_PRODUCT_BASCKET_ORDER } from "../../store/slices/reducers/basketSliceOrder";
import { toast } from "react-toastify";
import { useWindowDimensions } from "../../utils";

const customStylesEditQte = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "33%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
const customStylesHistory = {
  content: {
    top: "55%",
    left: "50%",
    right: "0%",
    bottom: "0%",
    marginRight: "-0%",
    transform: "translate(20%, -50%)",
    height: "90vh",
    width: "42%",
    borderWidth: 0.2,
    // borderRadius: 12,
    borderTopLeftRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
const customStylesHistoryMobileVersion = {
  content: {
    top: "50%",
    left: "0%",
    right: "0%",
    bottom: "0%",
    marginRight: "-0%",
    transform: "translate(0%, -50%)",
    height: "100vh",
    width: "100vw",
    borderWidth: 0.2,
    // borderRadius: 12,
    borderTopLeftRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
const ListSectionProduct = ({
  listProductByCategory,
  averageCost,
  productsWeight,
  doGetProductsByCategoryStore,
  setProductId,
  setEditMode,
  setAddProductOpen,
  setProductToEditData,
  storeId,
  storeDetails,
}: any) => {
  const [editQteOpen, setEditQteOpen] = useState<boolean>(false);
  const [productToEdit, setProductToEdit] = useState<any>({});
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [productInHistory, setProductInHistory] = useState<any>();
  const [displayEditBtn, setDisplayEditBtn] = useState<boolean>(false);
  const [lineHovered, setLineHovered] = useState("");
  const [updatedListProds, setUpdatedListProds] = useState([]);
  const [openProductId, setOpenProductId] = useState<string | null>(null);
  const { width } = useWindowDimensions();

  const closeHistoryModal = () => {
    setOpenHistory(false);
  };
  const closeEditQteModal = () => {
    setEditQteOpen(false);
  };

  const openModalEdit = (prd: any) => {
    console.log("id", prd?._id);
    setProductToEdit(prd);
    setEditQteOpen(true);
  };

  const doOpenModalHistory = (prd: any) => {
    setOpenHistory(true);
    setProductInHistory(prd);
  };

  const openEditMode = (prd: any) => {
    setEditMode(true);
    setProductId(prd?.product?._id);
    setAddProductOpen(true);
    setProductToEditData({
      name: prd?.product?.name,
      price: prd?.product?.cost,
      weight: prd?.product?.portion,
      unit: prd?.product?.unit,
    });
  };
  const updatedList = listProductByCategory.map((product: any) => ({
    ...product,
    isOpen: false,
  }));
  const store = useStore();

  useEffect(() => {
    if (!storeId) {
      const updatedList = listProductByCategory.map((product: any) => ({
        ...product,
        isOpen: false,
      }));
      setUpdatedListProds(updatedList);
    }
  }, []);

  const toggleDropDown = (productId: string) => {
    setOpenProductId((prevId) => (prevId === productId ? null : productId));
  };


  const addToBasket = (option: any) => {
    const prod = { ...option?.product };
    prod.quantity = 1;
    try {
      store.dispatch({
        type: PIZZA_SLICE_ADD_PRODUCT_BASCKET_ORDER,
        payload: prod,
      });
      toast.success("Le produit a été ajouter au panier");
    } catch (error) {
      console.log("errror redux");
    }
  };

  return (
    <>
      {storeId ? (
        <div className="prod-section-container">
          <div className="prod-section-header">
            <div className="prod-section-total">
              <span className="total-txt">Total</span>
              <span className="total-value-txt">
                {Number(productsWeight).toFixed(3)} Kg
              </span>
            </div>
            <div className="prod-section-moyen">
              <span className="moyen-price-txt">Prix Moyen</span>
              <span className="moyen-price-value-txt">
                {Number(averageCost).toFixed(3)} DT
              </span>
            </div>
          </div>

          <div className="table-container">
            <table className="table-style">
              <thead style={{ height: 60 }}>
                <tr>
                  <th className="title-txt"></th>
                  <th className="title-txt">Qte</th>
                  <th className="title-txt">Poids</th>
                  <th className="title-txt">PU</th>

                  <th className="title-txt"></th>
                </tr>
              </thead>
            
                <tbody>
                  {updatedList?.length > 0 ? (
                    updatedList?.map((prd: any, index: any) => {
                      console.log("prd 33333333", prd);

                      return (
                        <>
                          <tr
                            key={index}
                            className="line-table-style"
                            // onMouseEnter={() => {
                            //   setDisplayEditBtn(true);
                            //   setLineHovered(prd?.product?.name);
                            // }}
                            // onMouseLeave={() => setDisplayEditBtn(false)}
                          >
                            <td className="td-right">
                              <p className="td-right-text-style">
                                <p
                                  style={{ height: "10%" }}
                                  onClick={() => doOpenModalHistory(prd)}
                                >
                                  {prd?.product?.Category?.name} :{" "}
                                  {prd?.product?.name}
                                </p>
                                {displayEditBtn &&
                                lineHovered === prd?.product?.name ? (
                                  <div className="edit-wrapper">
                                    <p
                                      className="edit-hovered-txt"
                                      onClick={() => openEditMode(prd)}
                                    >
                                      Edit
                                    </p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </p>
                            </td>
                            <td className="td-center">
                              <p className="td-center-txt"> {prd?.quantity}</p>
                            </td>
                            <td className="td-center">
                              <p className="td-center-txt">
                                {prd?.product?.portion} {prd?.product?.unit}
                              </p>
                            </td>
                            <td className="td-left">
                              <p className="td-left-txt">
                                {prd?.product?.cost} DT
                              </p>
                            </td>
                            <td
                              className="td-left-left"
                              onClick={() => openModalEdit(prd)}
                            >
                              <p className="td-left-left-txt">
                                <i
                                  className="bx bx-edit"
                                  style={{ color: "#159B7B", fontSize: 24 }}
                                ></i>
                              </p>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
        
            </table>
          </div>
          <Modal
            isOpen={editQteOpen}
            onRequestClose={closeEditQteModal}
            style={customStylesEditQte}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <EditQuantityModal
              productToEdit={productToEdit}
              closeEditQteModal={closeEditQteModal}
              doGetProductsByCategoryStore={doGetProductsByCategoryStore}
            />
          </Modal>
          <Modal
            isOpen={openHistory}
            // onRequestClose={closeHistoryModal}
            style={ width > 768 ?  customStylesHistory : customStylesHistoryMobileVersion}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            {/* <ModalAddProduct closeAddModal={closeAddProductModal} categoryId={categoryId} doGetProductsByCategoryStore={doGetProductsByCategoryStore} /> */}
            <ModalHistoryProduct
              closeHistoryModal={closeHistoryModal}
              productId={productInHistory?.product}
            />
          </Modal>
        </div>
      ) : (
        <div className="prod-section-container">
          <div className="prod-section-header">
            <div className="prod-section-total">
              <span className="total-txt">Total</span>
              <span className="total-value-txt">
                {Number(productsWeight).toFixed(3)} Kg
              </span>
            </div>
            <div className="prod-section-moyen">
              <span className="moyen-price-txt">Prix Moyen</span>
              <span className="moyen-price-value-txt">
                {Number(averageCost).toFixed(3)} DT
              </span>
            </div>
          </div>

          <div className="table-container">
            <table className="table-style-ls" style={{minWidth : 900}}>
              <thead style={{ height: 60 }}>
                <tr>
                  <th className="title-txt">Item</th>
                  <th className="title-txt">Qte</th>
                  <th className="title-txt">Poids</th>
                  <th className="title-txt">PU</th>
                  <th className="title-txt"></th>
                </tr>
              </thead>
              <tbody className="content-ls">
                {listProductByCategory?.length > 0 ? (
                  listProductByCategory.map((prd: any, index: any) => {
                const isOpen = prd?.product?._id === openProductId;
                    console.log("isOpen" , isOpen);
                    

                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td
                            className="product-name-td-ls"
                            style={{
                              backgroundColor: isOpen ? "#159B7B" : "#fff",
                              borderTopLeftRadius: 8,
                              borderBottomLeftRadius: 8,
                            }}
                          >
                            <span style={{ color: isOpen ? "#fff" : "#000" }}>
                              {prd?.product?.name}
                            </span>
                          </td>
                          <td
                            className="qte-td-ls"
                            style={{
                              backgroundColor: isOpen ? "#159B7B" : "#fff",
                            }}
                          >
                            <span style={{ color: isOpen ? "#fff" : "#000" }}>
                              {prd?.quantity ? prd?.quantity : "--"}
                            </span>
                          </td>
                          <td
                            className="weight-td-ls"
                            style={{
                              backgroundColor: isOpen ? "#159B7B" : "#fff",
                            }}
                          >
                            <span style={{ color: isOpen ? "#fff" : "#000" }}>
                              {" "}
                              {prd?.product?.portion} {prd?.product?.unit}
                            </span>
                          </td>
                          <td
                            className="price-td-ls"
                            style={{
                              backgroundColor: isOpen ? "#159B7B" : "#fff",
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                            }}
                          >
                            <span style={{ color: isOpen ? "#fff" : "#000" }}>
                              {prd?.product?.cost} Dt
                            </span>
                            <i
                              className={`bx ${
                                isOpen ? "bx-chevron-up" : "bx-chevron-down"
                              }`}
                              onClick={() => toggleDropDown(prd?.product?._id)}
                              style={{
                                color: isOpen ? "#fff" : "#159B7B",
                                fontSize: 24,
                              }}
                            ></i>
                          </td>
                          <td className="td-btn-edit-basket-content-ls">
                            <div
                              className="btn-edit-basket-content-ls"
                              style={{
                                backgroundColor: isOpen ? "#159B7B" : "#fff",
                                borderRadius: 8,
                              }}
                            >
                              <button
                                className="btn-basket-ls"
                                style={{
                                  backgroundColor: isOpen ? "#159B7B" : "#fff",
                                }}
                              >
                                <i
                                  onClick={() => addToBasket(prd)}
                                  className="bx bx-basket"
                                  style={{
                                    fontSize: 24,
                                    color: isOpen ? "#fff" : "#DF9F1C",
                                  }}
                                ></i>
                              </button>
                              <div />
                              <button
                                className="btn-edit-ls"
                                style={{
                                  backgroundColor: isOpen ? "#159B7B" : "#fff",
                                }}
                                onClick={() => openEditMode(prd)}
                              >
                                {" "}
                                <i
                                  className="bx bx-edit"
                                  style={{
                                    fontSize: 24,
                                    color: isOpen ? "#fff" : "#159B7B",
                                  }}
                                ></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <td colSpan={4}>
                          {isOpen ?  (
                            <table style={{ backgroundColor: "#fff" }}>
                              <thead style={{ height: 10 }}>
                                <tr>
                                  <th className="title-txt">Item</th>
                                  <th className="title-txt">Qte</th>
                                  <th className="title-txt">Poids</th>
                                  <th className="title-txt">Poid totale</th>
                                  <th className="title-txt"></th>{" "}
                                  {/* Ensure this matches the number of <td> elements */}
                                </tr>
                              </thead>
                              <tbody>
                                {prd?.stores && prd?.stores.length > 0 ? (
                                  prd.stores
                                    .filter(
                                      (store: any) =>
                                        store?.storeName !== "General Stock"
                                    )
                                    .map((store: any, index: any) => (
                                      <tr key={index}>
                                        <td>
                                          <span>
                                            <span
                                              style={{
                                                height: "10%",
                                                fontSize: 18,
                                                fontWeight: 700,
                                              }}
                                            >
                                              {store?.storeName}
                                            </span>
                                          </span>
                                        </td>
                                        <td>
                                          <span style={{ color: "#000" }}>
                                            {store?.totalQuantity}
                                          </span>
                                        </td>
                                        <td className="td-center-ls">
                                          <span style={{ color: "#000" }}>
                                            {prd?.product?.portion}{" "}
                                            {prd?.product?.unit}
                                          </span>
                                        </td>
                                        <td className="td-left-ls">
                                          <span style={{ color: "#000" }}>
                                            {store?.totalWeight} Kg
                                          </span>
                                        </td>
                                        <td className="td-left-ls">
                                          {/* Empty cell for alignment */}
                                        </td>
                                      </tr>
                                    ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={5}
                                      style={{
                                        textAlign: "center",
                                        color: "#888",
                                      }}
                                    >
                                      No data available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          ) : (
                            <></>
                          )}
                        </td>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          <Modal
            isOpen={editQteOpen}
            onRequestClose={closeEditQteModal}
            style={customStylesEditQte}
            contentLabel="Edit Quantity Modal"
            ariaHideApp={false}
          >
            <EditQuantityModal
              productToEdit={productToEdit}
              closeEditQteModal={closeEditQteModal}
              doGetProductsByCategoryStore={doGetProductsByCategoryStore}
            />
          </Modal>
          <Modal
            isOpen={openHistory}
            onRequestClose={closeHistoryModal}
            style={ width > 768 ?  customStylesHistory : customStylesHistoryMobileVersion}
            contentLabel="History Modal"
            ariaHideApp={false}
          >
            <ModalHistoryProduct
              closeHistoryModal={closeHistoryModal}
              productId={productInHistory?.product}
            />
          </Modal>
        </div>
      )}
    </>
  );
};
export default ListSectionProduct;
