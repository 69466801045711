// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-container-as {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.products-container-as .alimentation-container {
  height: 80%;
  width: 95%;
  background-color: #fff;
}
.products-container-as .alimentation-footer {
  min-height: 15%;
  width: 95%;
  background-color: cadetblue;
}

.row {
  background-color: #f8f8f8;
}

.animation-wrapper {
  height: 100%;
  width: 100%;
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.content-products-and-bar {
  overflow: auto;
  height: 85vh;
}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/AlimentationScreen/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACF;AAAE;EACE,WAAA;EACA,UAAA;EACA,sBAAA;AAEJ;AAAE;EACE,eAAA;EACA,UAAA;EACA,2BAAA;AAEJ;;AAEA;EACE,yBAAA;AACF;;AACA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AAEF;;AAAA;EACE,cAAA;EACA,YAAA;AAGF","sourcesContent":[".products-container-as {\n  width: 100%;\n  height: 85vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  overflow: auto;\n  .alimentation-container {\n    height: 80%;\n    width: 95%;\n    background-color: #fff;\n  }\n  .alimentation-footer {\n    min-height: 15%;\n    width: 95%;\n    background-color: cadetblue;\n  }\n}\n\n.row {\n  background-color: #f8f8f8;\n}\n.animation-wrapper {\n  height: 100%;\n  width: 100%;\n  padding: 20px;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n}\n.content-products-and-bar{\n  overflow: auto;\n  height: 85vh;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
