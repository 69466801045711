export const slicesArray = [
  "transactionDataSlice",
  "bascketSlice",
  "bascketSliceOrder",
];
export const listToBeReseted = [
  "transactionDataSlice",
  "bascketSlice",
  "bascketSliceOrder",
];
