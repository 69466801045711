import { formatDateAndTime } from "../../utils";
import { transactionType } from "../../utils/types/transaction";
import "./index.scss";
interface props {
  transaction: transactionType;
  closeModal: () => void;
  currentTransaction: any;
}
const CardTransactionHistory = ({
  transaction,
  closeModal,
  currentTransaction,
}: props) => {
  const calculWeightInKg = (portion: any, quantity: any, unit: any) => {
    if (unit === "gramme") {
      return (portion * quantity) / 1000;
    } else {
      return portion * quantity;
    }
  };
  return (
    <div className="card-transaction-history-container">
      <div className="card-transaction-history-header">
        <div className="card-transaction-history-date-container">
          <p className="card-transaction-history-date-txt">
            {formatDateAndTime(currentTransaction?.date)}
          </p>
        </div>
        <div onClick={() => closeModal()}>
          <i className="bx bx-x" style={{ fontSize: 28, fontWeight: 400 }}></i>
        </div>
      </div>
      <div className="card-transaction-history-head">
        <div className="card-transaction-history-track">
          <p>De</p>
          <p className="card-transaction-history-sender-value">
            {currentTransaction?.sender?.owner?.name}
          </p>
        </div>
        <div className="card-transaction-history-track">
          <p>À</p>
          <p className="card-transaction-history-sender-value">
            {currentTransaction?.receiver?.owner?.name}
          </p>
        </div>
      </div>
      <div className="card-transaction-history-body">
        {transaction?.details.length > 0 ? (
          transaction?.details?.map((prod: any, index: any) => (
            <div key={index} className="card-transaction-history-body-item">
              <div className="card-transaction-history-body-item-portion-container">
                <p className="card-transaction-history-body-item-portion-txt">
                  {calculWeightInKg(
                    prod?.product?.portion,
                    prod?.quantity,
                    prod?.product?.unit
                  )}{" "}
                  Kg
                </p>
              </div>
              <div className="card-transaction-history-body-item-product-container">
                <div className="card-transaction-history-product-name-container">
                  <p className="card-transaction-history-product-name-txt">
                    {prod?.product?.name}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default CardTransactionHistory;
