import React, { useState } from "react";
import "./index.scss";
import Lottie from "react-lottie";
import { defaultOptionsEmpty } from "../../assets/lottie-animations/animationOptions";

function ListProviders({
  listProviders,
  setIsOpen,
  setCurrentProvider,
  setEditMode,
  loadMore,
  totalePages,
  page,
  query,
}: any) {
  return (
    <div className="container-providers-lp">
      {listProviders?.length > 0 ? (
        <table className="table-style-lp">
          <thead style={{ height: 60 }}>
            <tr>
              <th className="title-txt-lp">Nom</th>
              <th className="title-txt-lp">Tél</th>
              <th className="title-txt-lp">Email</th>
              <th className="title-txt-lp">Description </th>
            </tr>
          </thead>

          <tbody
            className="t-body-lp"
            // onScroll={handleScroll}
          >
            {listProviders?.length > 0 ? (
              listProviders?.map((provider: any, index: any) => {
                return (
                  <tr
                    key={index}
                    className="line-table-style-lp"
                    onClick={() => {
                      setIsOpen(true);
                      setCurrentProvider(provider);
                      setEditMode(true);
                    }}
                  >
                    <td className="td-right-lp">
                      <p className="td-right-text-style-lp">
                        {provider?.fullname}
                      </p>
                    </td>
                    <td className="td-center-lp">
                      <p className="td-center-txt-lp">
                        {" "}
                        {provider?.phoneNumber}
                      </p>
                    </td>
                    <td className="td-center-lp">
                      <p className="td-center-txt-lp">{provider?.email}</p>
                    </td>
                    <td className="td-center-lp">
                      <p className="td-center-txt-lp">
                        {provider?.description}
                      </p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
      ) : (
        <Lottie options={defaultOptionsEmpty} height={"60%"} width={"30%"} />
      )}
      {page < totalePages && !Boolean(query) ? (
        <div className="btn-load-more-providers-container-lp">
          <button
            className="btn-load-more-providers-lp"
            onClick={() => loadMore()}
          >
            Load More
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ListProviders;
