import { Formik } from "formik";
import "./index.scss";
import addCategory from "../../../services/Add-Category";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import updateCategory from "../../../services/update-category";

const ModalAddCategory = ({
  closeAddModal,
  doGetAllCategories,
  editMode,
  categoryToEdit,
}: any) => {
  const [loading, setLoading] = useState(false);
  const doAddCategory = (values: any) => {
    setLoading(true);
    addCategory(values)
      .then((response) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("server error !");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success("category added successfuly !");
          doGetAllCategories();
          closeAddModal();
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  const doEditCategory = (values: any) => {
    setLoading(true);
    updateCategory(values, categoryToEdit?._id)
      .then((response) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("server error !");
          return;
        }
        if (!response.data.success) {
          toast.error(response.data.message);
          return;
        }
        if (response.data.success) {
          toast.success("category updated successfuly !");
          doGetAllCategories();
          closeAddModal();
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  return (
    <Formik
      initialValues={{
        name: editMode ? categoryToEdit?.name : "",
      }}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = "Required";
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          editMode ? doEditCategory(values) : doAddCategory(values);
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }: any) => (
        <form className="form-container-add-categ" onSubmit={handleSubmit}>
          <div className="modal-add-category-container">
            <div className="modal-add-category-header">
              <p className="new-categ-txt">
                {editMode ? "Edit Catégorie" : "Nouvelle Catégorie"}{" "}
              </p>
              <i
                className="bx bx-x"
                style={{ fontSize: 28 }}
                onClick={() => closeAddModal()}
              ></i>
            </div>
            <div className="modal-add-category-body">
              <input
                name="name"
                value={values.name}
                placeholder="Nom de catégorie"
                className="input-add-categ"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p
                style={{
                  color: "red",
                  fontSize: 11,
                  fontWeight: 400,
                  textAlign: "start",
                  width: "100%",
                  paddingLeft: 30,
                  marginTop: 10,
                }}
              >
                {errors.name && touched.name && errors.name}
              </p>
            </div>
            <div className="modal-add-category-footer">
              <button className="add-categ-cancel-btn">Annuler</button>
              <button
                className="add-categ-confirm-btn"
                type="submit"
                disabled={loading}
              >
                Confirmer
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default ModalAddCategory;
