// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.high-content {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.content {
  height: 100vh;
  width: 70vw;
  background-color: #f9f9f9;
}

.content-blur {
  filter: blur(2px);
}`, "",{"version":3,"sources":["webpack://./src/components/Layer/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EAEA,yBAAA;EAEA,aAAA;EACA,mBAAA;EACA,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,WAAA;EACA,yBAAA;AADF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[".high-content {\n  height: 100vh;\n  max-height: 100vh;\n  width: 100vw;\n  max-width: 100vw;\n\n  background-color: #f9f9f9;\n\n  display: flex;\n  flex-direction: row;\n  overflow: hidden;\n}\n\n.content {\n  height: 100vh;\n  width: 70vw;\n  background-color: #f9f9f9;\n}\n.content-blur {\n  filter: blur(2px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
