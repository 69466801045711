import "./index.scss";
const NotifRejectItem = () => {
  return (
    <div className="one-item-notif-content-r">
      <div className="one-item-notif-p-1-r">
        <div className="img-store-container-r">
          <img src="./../../assets/redStore.png" alt="greenStore" />
        </div>
      </div>
      <div className="one-item-notif-p-2-r">
        <div className="txt-info-container-r">
          <p className="txt-info-r">Le store X a rejeté le stock envoyé .</p>
        </div>
      </div>
      <div className="one-item-notif-p-3-r">
        <p className="txt-consult-r">Voir la liste</p>
        <i
          className="bx bx-right-arrow-alt"
          style={{ fontSize: 40, color: "#CC4242", marginLeft: 10 }}
        ></i>
      </div>
    </div>
  );
};
export default NotifRejectItem;
