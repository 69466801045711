import { useEffect, useState } from "react";
import "./index.scss";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import updateQuantityProduct from "../../../services/put-quantity-product";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const EditQuantityModal = ({
  productToEdit,
  closeEditQteModal,
  doGetProductsByCategoryStore,
}: any) => {
  const [quantity, setQuantity] = useState<number | undefined>(undefined); // Allow undefined for empty input
  const [expirationDate, setExpirationDate] = useState<Date | null>(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { storeId } = useParams();

  useEffect(() => {
    setQuantity(productToEdit?.quantity);
  }, [productToEdit]);

  const handleQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Check if value is empty or a valid number (including floating-point numbers)
    if (value === "" || !isNaN(Number(value))) {
      setQuantity(value === "" ? undefined : Number(value));
    }
  };

  const doUpdateQuantityProduct = () => {
    if (quantity === undefined) {
      toast.error("La quantité est invalide !");
      return;
    }

    const bodyData = {
      expirationDate: expirationDate,
      quantity: quantity - (productToEdit?.quantity || 0),
    };

    updateQuantityProduct(bodyData, storeId, productToEdit?.product?._id)
      .then((response) => {
        if (response.data.success) {
          doGetProductsByCategoryStore();
          closeEditQteModal();
          toast.success("Quantité mise à jour avec succès !");
        } else {
          if (response?.data?.status === 500) {
            toast.error("Erreur du serveur !");
            return;
          }
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
        }
      })
      .catch((error) => {
        toast.error("Une erreur est survenue !");
      });
  };

  const handleDateChange = (newDate: Date | Date[] | null) => {
    if (newDate instanceof Date) {
      setExpirationDate(newDate);
    }
  };

  const openCalendar = () => {
    setIsCalendarOpen(true);
  };

  const closeCalendar = () => {
    setIsCalendarOpen(false);
  };

  return (
    <div className="edit-qte-modal-container">
      <div className="edit-qte-modal-header">
        <p className="title-txt">Mise à jour de stock</p>
      </div>
      <div className="edit-qte-modal-body">
        <div className="prod-name-container">
          <p className="prod-name-txt">
            {productToEdit?.product?.Category?.name} :{" "}
            {productToEdit?.product?.name} {productToEdit?.product?.portion}{" "}
            {productToEdit?.product?.unit}{" "}
          </p>
        </div>
        <div className="update-qte-container">
          <button
            disabled={quantity === undefined || quantity === 0}
            className="btn-minus"
            onClick={() => handleQuantityChange((quantity || 0) - 1)}
          >
            <i
              className="bx bx-minus"
              style={{ color: "#159B7B", fontSize: 64, fontWeight: "700" }}
            ></i>
          </button>
          <input
            type="number"
            className="input-qte"
            value={quantity === undefined ? "" : quantity}
            onChange={handleInputChange}
            step="any" // Allows floating-point numbers
          />
          <button
            className="btn-plus"
            onClick={() => handleQuantityChange((quantity || 0) + 1)}
          >
            <i
              className="bx bx-plus"
              style={{ color: "#159B7B", fontSize: 64, fontWeight: "700" }}
            ></i>
          </button>
        </div>
        {quantity !== undefined && quantity > (productToEdit?.quantity || 0) && (
          <div className="set-expiration-date-container">
            <div className="date-container">
              <div style={{ width: "45%" }}>
                <p className="date-txt">Date d'expiration</p>
              </div>
              <div className="input-calendar-hight-container">
                <div
                  className="input-calendar-container"
                  onClick={openCalendar}
                >
                  <input
                    type="text"
                    value={expirationDate ? expirationDate.toDateString() : ""}
                    readOnly
                    className="input-stl"
                  />
                  <div>
                    <i
                      className="bx bx-calendar"
                      style={{ fontSize: 32, color: "#159B7B" }}
                    ></i>
                  </div>
                </div>
                {isCalendarOpen && (
                  <div className="calendar-container">
                    <div className="close-button" onClick={closeCalendar}>
                      <i className="bx bx-x"></i>
                    </div>
                    <Calendar
                      onChange={(newDate: any) => handleDateChange(newDate)}
                      value={expirationDate}
                      onClickDay={closeCalendar} // Close the calendar when a date is clicked
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="edit-qte-modal-footer">
        <button className="btn-cancel" onClick={closeEditQteModal}>
          Annuler
        </button>
        <button
          className="btn-confirm"
          onClick={doUpdateQuantityProduct}
        >
          Confirmer
        </button>
      </div>
    </div>
  );
};

export default EditQuantityModal;
