import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./index.scss";

// Validation schema using Yup
const validationSchema = Yup.object({
  serviceName: Yup.string().required("Nom du service est requis"),
  beneficiaryName: Yup.string().required("Nom du bénéficiaire est requis"),
  phoneNumber: Yup.string().required("Numéro de téléphone est requis"),
  email: Yup.string().email("Email invalide").required("Email est requis"),
  price: Yup.number()
    .required("Prix est requis")
    .positive("Le prix doit être positif"),
  description: Yup.string(),
});

const ServiceForm = ({
  submitForm,
  setDisplayForm,
  serviceToEdit,
  setEditMode,
  editMode,
}: any) => {
  const initialValues = {
    serviceName: serviceToEdit?.name ? serviceToEdit?.name : "",
    beneficiaryName: serviceToEdit?.benefactor?.name
      ? serviceToEdit?.benefactor?.name
      : "",
    phoneNumber: serviceToEdit?.benefactor?.phone
      ? serviceToEdit?.benefactor?.phone
      : "",
    email: serviceToEdit?.benefactor?.email
      ? serviceToEdit?.benefactor?.email
      : "",
    price: serviceToEdit?.price ? serviceToEdit?.price : "",
    description: serviceToEdit?.description ? serviceToEdit?.description : "",
  };

  const handleSubmit = (values: any) => {
    console.log("Form data:", values);
    submitForm(values);
    // Add your submit logic here
  };

  return (
    <div className="service-form-container">
      <h3>Nouveau Service:</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="form-group">
              <div className="form-row">
                <div className="form-input">
                  <label>Nom du service:</label>
                  <Field type="text" name="serviceName" placeholder="Service" />
                  <ErrorMessage
                    name="serviceName"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-input">
                  <label>Nom du bénéficiaire:</label>
                  <Field type="text" name="beneficiaryName" placeholder="Nom" />
                  <ErrorMessage
                    name="beneficiaryName"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-input">
                  <label>Numéro de Tel:</label>
                  <Field type="text" name="phoneNumber" placeholder="Num" />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-input">
                  <label>Email:</label>
                  <Field type="email" name="email" placeholder="Email" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-input">
                  <label>Prix:</label>
                  <Field type="number" name="price" placeholder="Prix" />
                  <ErrorMessage
                    name="price"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-input">
                  <label>
                    Si nécessaire, veuillez nous fournir une description du
                    service:
                  </label>
                  <Field
                    as="textarea"
                    name="description"
                    placeholder="Description"
                    rows={4}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="form-buttons">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => { setDisplayForm(false) ; setEditMode(false)}}
                >
                  Annuler
                </button>
                <button type="submit" className="submit-button">
                  Enregistrer
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ServiceForm;
