import "./index.scss";

import { product } from "../../utils/types/product";

import { useNavigate } from "react-router-dom";
interface CardProps {
  product: product;
  storeId?: any;
  inStore?: any;
  storeDetails?: any;
  doEditCategory?: any;
}

function Card({
  product,
  storeId,
  inStore,
  storeDetails,
  doEditCategory,
}: CardProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <div className="card-container">
      <div className="card-header">
        <div className="card-header-wrapper">
          <p
            style={{ marginRight: 10, color: "#159B7B", fontWeight: "700" }}
            onClick={() =>
              Boolean(inStore)
                ? navigate(`/section-details/${storeId}/${product?._id}`, {
                    state: { product, storeDetails },
                  })
                : navigate(`/section-details/${product?._id}`, {
                    state: { product },
                  })
            }
          >
            {product?.name}
          </p>
          <p style={{ color: "#878787" }}>{`(kg)`}</p>
        </div>
        <div className="card-categorie-wrapper">
          <i
            className="bx bx-edit"
            style={{ fontSize: 28 }}
            onClick={() =>
              Boolean(inStore)
                ? navigate(`/section-details/${storeId}/${product?._id}`, {
                    state: { product },
                  })
                : doEditCategory(product)
            }
          ></i>
        </div>
      </div>
      <div className="card-details">
        <div>
          <p style={{ fontWeight: "700", fontSize: 32 }}>
            {Number(product?.productsWeight).toFixed(3)} {"Kg"}
          </p>
        </div>
        <div className="average-coast-wrapper">
          <span className="price-label">Prix Moyen</span>
          <span className="price-value">
            {product?.averageCost?.toFixed(3)} DT
          </span>
        </div>
      </div>
    </div>
  );
}

export default Card;
