import "./index.scss";
const ProdAchatItem = ({prodAchat} : any) => {
  return (
    <div className="prod-item-achat-container">
      <div className="prod-name-achat-content">
        <p className="prod-name-achat-txt">{prodAchat?.name}</p>
      </div>
      <div className="prod-qty-unit-content">
        <div className="qty-content">
          <p className="qty-txt">x{prodAchat?.quantity}</p>
        </div>
        <div className="weight-unity-content">
          <p className="weight-txt">{prodAchat?.weight}</p>
          <p className="unity-txt">{prodAchat?.unity}</p>
        </div>
      </div>
    </div>
  );
};
export default ProdAchatItem;
