import { store } from "../../utils/types/store";
import "./index.scss";
interface CardStoreProps {
  store: store;
}
function CardStore({ store }: CardStoreProps): JSX.Element {
  return (
    <div className="card-store-content">
      <div className="card-store-wrapper">
        <div className="card-store-name-content" />
        <p className="card-store-name-txt">{store?.name}</p>
      </div>
      <div className="store-name-wrapper" style={{ width: "200px" }}>
        <span
          style={{
            fontSize: 16,
            fontWeight: 700,
            height: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {store?.adresses?.name}
        </span>
      </div>
      {store?.restock ? (
        <div className="card-store-alimentation-content">
          <div className="card-store-alimentation-txt-container">
            <p style={{ height: "40%" }}>Besoin d'alimentation</p>
          </div>
          <div className="card-store-alimentation-img-container">
            <img src="./../../assets/besoinStore.png" alt="storeIcon" />
          </div>
        </div>
      ) : (
        <div className="card-store-alimentation-content"></div>
      )}
    </div>
  );
}
export default CardStore;
