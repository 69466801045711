import animationData from "../../assets/lottie-animations/empty.json";
import animationDataLoading from "../../assets/lottie-animations/loading.json";
export const defaultOptionsEmpty = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const defaultOptionsLoading = {
  loop: true,
  autoplay: true,
  animationData: animationDataLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
