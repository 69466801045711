// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prod-item-achat-container {
  width: 100%;
  height: 60px;
  display: flex;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  margin-top: 10px;
}
.prod-item-achat-container .prod-name-achat-content {
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.prod-item-achat-container .prod-name-achat-content .prod-name-achat-txt {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  height: 30%;
}
.prod-item-achat-container .prod-qty-unit-content {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.prod-item-achat-container .prod-qty-unit-content .qty-content {
  display: flex;
  align-items: center;
  height: 100%;
}
.prod-item-achat-container .prod-qty-unit-content .qty-content .qty-txt {
  font-size: 20px;
  font-weight: 600;
  height: 35%;
}
.prod-item-achat-container .prod-qty-unit-content .weight-unity-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  height: 100%;
}
.prod-item-achat-container .prod-qty-unit-content .weight-unity-content .weight-txt {
  font-size: 20px;
  font-weight: 600;
  color: #159b7b;
  height: 35%;
}
.prod-item-achat-container .prod-qty-unit-content .weight-unity-content .unity-txt {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  height: 20%;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/ProdAchatItem/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,UAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,WAAA;AAGN;AAAE;EACE,UAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AAGN;AAFM;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AAIR;AADI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AAGN;AAFM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;AAIR;AAFM;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;AAIR","sourcesContent":[".prod-item-achat-container {\n  width: 100%;\n  height: 60px;\n  display: flex;\n  background-color: #fff;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 6px;\n  margin-top: 10px;\n  .prod-name-achat-content {\n    width: 75%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    padding-left: 15px;\n    .prod-name-achat-txt {\n      font-size: 20px;\n      font-weight: 600;\n      color: #000;\n      height: 30%;\n    }\n  }\n  .prod-qty-unit-content {\n    width: 25%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    .qty-content {\n      display: flex;\n      align-items: center;\n      height: 100%;\n      .qty-txt {\n        font-size: 20px;\n        font-weight: 600;\n        height: 35%;\n      }\n    }\n    .weight-unity-content {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      padding-right: 10px;\n      height: 100%;\n      .weight-txt {\n        font-size: 20px;\n        font-weight: 600;\n        color: #159b7b;\n        height: 35%;\n      }\n      .unity-txt {\n        font-size: 14px;\n        font-weight: 600;\n        color: #000;\n        height: 20%;\n        margin-left: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
