import { useNavigate } from "react-router-dom";
import "./index.scss";

const StoreDetailsHeader = ({ storeDetails }: any) => {
  const navigate = useNavigate();
   console.log("storeDetails" , storeDetails);
   
  return (
    <div className="store-details-header-container">
      <div className="store-details-left-side">
        <div>
          <img src="./../../../assets/StoreIconBg.png" alt="StoreIconBg" />
        </div>
        <div className="store-header-details">
          <p className="store-details-store-name">
            {storeDetails?.owner?.name}{" "}
          </p>
          <p>{storeDetails?.owner?.adresses?.name}</p>
        </div>
      </div>
      <div className="store-details-right-side">
        <button
          className="store-details-btn-aliment"
          onClick={() => navigate(`/alimentation/${storeDetails?.owner?._id}`)}
        >
          <img src="./../../assets/icon-alimenter.png" alt="alimentationIcon" />
          <p className="alimenter-txt">Alimenter</p>
        </button>
      </div>
    </div>
  );
};
export default StoreDetailsHeader;
