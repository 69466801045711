import React from "react";
import {
  capitalizeFirstLetter,
  formatDateAndTime,
  getStatus,
} from "../../utils";
import Select, { StylesConfig } from "react-select";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import "./index.scss";
import { defaultOptionsEmpty } from "../../assets/lottie-animations/animationOptions";
const dot = (color = "transparent"): any => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});
const circleStyles: any = {
  pending: { color: "#FFE6A6" },
  completed: { color: "#DBF2DA" },
  cancelled: { color: "#FFCDCD" },
};

const styleInput = (status: any) => {
  let colourStyles = {};
  return (colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "#ddd"
          : undefined,
        color: isDisabled ? "#ccc" : "#000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "#fd44"
            : undefined,
        },
      };
    },
    input: (styles: any) => ({ ...styles, ...dot() }),
    placeholder: (styles: any) => ({ ...styles, ...dot("#ccc") }),
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      ...dot(circleStyles[status]?.color),
    }),
  });
};
function ListOrdersServices({
  loadMore,
  orderList,
  doUpdateStatus = () => {},
}: any) {
  const navigate = useNavigate();
  const statuses = [
    { label: "En attente d’achat", value: "pending" },
    { label: "Achat effectué", value: "completed" },
    { label: "Achat annulé", value: "cancelled" },
  ];

  const handleOptionSelect = (option: any, order: any) => {
    const bodyData = {
      status: option?.value,
    };
    console.log("optionsss", option, order, order?._id, bodyData);
    doUpdateStatus(bodyData, order?._id);
  };
  return (
    <div className="table-content">
      {orderList?.length > 0 ? (
        <table className="table">
          <thead style={{ height: 60 }}>
            <tr>
              <th className="title-txt">Item</th>
              <th className="title-txt">Date</th>
              <th className="title-txt">Quantité</th>
              <th className="title-txt">Etat</th>
            </tr>
          </thead>
          <tbody>
            {orderList
              .map((order: any, index: any) => (
                <tr key={index} className="line-table-style">
                  <td
                    className="td-right"
                    onClick={() =>
                      navigate(`/purchaceServicesDetails/${order?._id}`, {
                        state: { purchace: order },
                      })
                    }
                  >
                    <span className="td-right-text-style">
                      {Boolean(order?.name)
                        ? capitalizeFirstLetter(order?.name)
                        : "Not set yet"}
                    </span>
                  </td>
                  <td className="td-center">
                    <span className="td-center-txt">
                      {Boolean(order?.date)
                        ? formatDateAndTime(order?.date)
                        : "Not set yet"}
                    </span>
                  </td>
                  <td className="td-center-type">
                    <span className="td-center-txt">
                      {order?.products?.length} articles
                    </span>
                  </td>
                  <td className="td-right-status">
                    <Select
                      classNamePrefix="select"
                      options={[...statuses, { id: order?._id }]}
                      value={getStatus(order?.status)}
                      styles={styleInput(order?.status)}
                      onChange={(option) => handleOptionSelect(option, order)}
                    />
                  </td>
                </tr>
              ))
              .reverse()}
          </tbody>
        </table>
      ) : (
        <div className="empty-list-purchace">
          <Lottie options={defaultOptionsEmpty} height={"60%"} width={"30%"} />
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button className="btn-load-more" onClick={() => loadMore()}>
          load more <i className="bx bx-loader-alt"></i>
        </button>
      </div>
    </div>
  );
}

export default ListOrdersServices;
