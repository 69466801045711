// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prods-achat-container {
  min-height: 75vh;
  width: 100%;
  padding-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/NotificationDetailsScreen/index.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;EACA,iBAAA;AACJ","sourcesContent":[".prods-achat-container{\n    min-height: 75vh;\n    width: 100%;\n    padding-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
