// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-notif {
  min-height: 85vh;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/NotificationScreen/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF","sourcesContent":[".container-notif {\n  min-height: 85vh;\n  width: 100%;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
