import { useEffect, useState } from "react";
import getTransactionsHistoryByProduct from "../../../services/get-transactions-with-pagination-by-product";
import "./index.scss";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";

const ModalHistoryProduct = ({ closeHistoryModal, productId }: any) => {
  const [listTransactions, setListTransactions] = useState<any[]>([]);
  const limit = 10;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const { storeId } = useParams();

  const doGetTransactionsHistoryByProduct = () => {
    setLoading(true);
    getTransactionsHistoryByProduct(page, limit, productId?._id, storeId)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setListTransactions(response?.data?.content?.transactions);
          setTotalPages(response?.data?.content?.totalPages);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  function formatDateAndTime(inputDate: any) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
  function formatDate(inputDate: any) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  useEffect(() => {
    doGetTransactionsHistoryByProduct();
  }, [page]);

  const changePage = (newPage: any) => {
    setPage(newPage);
  };

  return (
    <>
      <div className="modal-history-product-container">
        <div className="modal-history-product-header">
          <div className="modal-history-product-header-head">
            <p className="modal-history-product-title-txt">
              Historique de transfert
            </p>
            <div
              className="modal-history-product-close-btn-container"
              onClick={() => closeHistoryModal()}
            >
              <i className="bx bx-x" style={{ fontSize: 28 }}></i>
            </div>
          </div>
          <div className="modal-history-product-name-container">
            <p className="modal-history-product-name-txt">{productId?.name}</p>
          </div>
        </div>
        {loading ? (
          <Lottie
            options={defaultOptionsLoading}
            height={"20%"}
            width={"20%"}
          />
        ) : (
          <div
            className="modal-history-product-body"
            style={{ overflow: "hidden" }}
          >
            <div className="modal-history-product-body-table-container">
              <table className="modal-history-product-body-table-style">
                <thead style={{ height: 60 }}>
                  <tr>
                    <th className="modal-history-product-body-title-txt">De</th>
                    <th className="modal-history-product-body-title-txt">
                      Vers
                    </th>
                    <th className="modal-history-product-body-title-txt">
                      Stock
                    </th>
                    <th className="modal-history-product-body-title-txt">
                      Date de transfert
                    </th>
                    <th className="modal-history-product-body-title-txt">
                      Date d'expiration
                    </th>
                  </tr>
                </thead>
                <tbody style={{ paddingTop: 20 }}>
                  {listTransactions?.length > 0 ? (
                    listTransactions?.map((prd: any, index: any) => {
                      const isEvenRow = index % 2 === 0;
                      return (
                        <tr
                          key={index}
                          className={`modal-history-product-body-line-table-style ${
                            isEvenRow ? "even-row" : ""
                          }`}
                        >
                          <td className="modal-history-product-body-td-right">
                            <p className="modal-history-product-body-td-right-text-style">
                              {prd?.sender?.owner?.name}
                            </p>
                          </td>
                          <td className="modal-history-product-body-td-center">
                            <p className="modal-history-product-body-td-center-txt">
                              {prd?.receiver?.owner?.name}
                            </p>
                          </td>
                          <td className="modal-history-product-body-td-center">
                            <p className="modal-history-product-body-td-center-txt">
                              {prd?.details?.quantity *
                                prd?.details?.product?.portion}{" "}
                              {prd?.details?.product?.unit}
                            </p>
                          </td>
                          <td className="modal-history-product-body-td-left">
                            <p className="modal-history-product-body-td-left-txt">
                              {formatDateAndTime(prd?.date)}
                            </p>
                          </td>
                          <td className="modal-history-product-body-td-left-left">
                            <p className="modal-history-product-body-td-left-left-txt">
                              {formatDate(prd?.details?.expirationDate)}
                            </p>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="modal-history-product-footer">
          <div className="buttons-pagination-container">
            <button
              className="button-container"
              onClick={() => changePage(page - 1)}
              disabled={page === 1}
            >
              <i
                className="bx bx-chevron-left"
                style={{ color: "#159B7B", fontSize: 28 }}
              ></i>
            </button>
            {(() => {
              const buttons = [];
              for (let i = 1; i <= totalPages; i++) {
                buttons.push(
                  <button
                    key={i}
                    className="button-container"
                    style={{ color: page === i ? "#159B7B" : "#000" }}
                    onClick={() => changePage(i)}
                  >
                    {i}
                  </button>
                );
              }
              return buttons;
            })()}
            <button
              className="button-container"
              onClick={() => changePage(page + 1)}
              disabled={page === totalPages}
            >
              <i
                className="bx bx-chevron-right"
                style={{ color: "#159B7B", fontSize: 28 }}
              ></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalHistoryProduct;
