import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

export type AppContextType = {
  availableRunners:"";
  setAvailableRunners: React.Dispatch<React.SetStateAction<any>>;
};
interface ContextValueType {
  availableRunners: any;
  setAvailableRunners: Dispatch<SetStateAction<any[]>>;
}
const AppContextInit = {
  availableRunners: {},
  setAvailableRunners: () => {},
};
const initialAvailableRunners: any = "";

export const AppContext = createContext<ContextValueType | undefined>(
  AppContextInit
);

const ContextApp: React.FC<any> = ({ children }) => {
  const [availableRunners, setAvailableRunners] = useState<any>(
    initialAvailableRunners
  );

  return (
    <AppContext.Provider
      value={{
        availableRunners,
        setAvailableRunners,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextApp;
