import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import getPurchaceById from "../../services/get-purchace-by-id";
import { capitalizeFirstLetter, formatDateAndTime } from "../../utils";
import HeaderComponent from "../Header";
import Layer from "../Layer";
import SideBar from "../SideBar";
import Modal from "react-modal";

import "./index.scss";
import DeleteProductPurchase from "../modals/DeleteProductPurchase";
import SelectStore from "../modals/SelectStore";
import { isValid } from "date-fns";
import ExperitionDate from "../modals/ExperitionDate";
const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "50vh",
    width: "50%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};
const customStylesStoreSelect = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "60vh",
    width: "40%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};
const customStylesEditDate = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "50vh",
    width: "40%",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function PurchaceDetails() {
  const [showMenu, setShowMenu] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const state = location.state || {};
  const [listProduct, setListProduct] = useState<any>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalStoreSelectIsOpen, setModalStoreSelectIsOpen] = useState(false);
  const [modalEditDate, setModalEditDate] = useState(false);
  const [productToDelete, setProductToDelete] = useState<any>(null);
  const [productToEdit, setProductToEdit] = useState<any>(null);
  const [purchase, setPurchase] = useState<any>(null);

  const doGetPurchaceById = () => {
    getPurchaceById(id)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setPurchase(response?.data?.content);
          const updatedProducts = response?.data?.content?.products.map(
            (prd: any, index: any) => {
              if (index === response?.data?.content?.products.length - 1) {
                return { ...prd, isOpen: false }; // Last product isValid is false
              }
              return { ...prd, isOpen: false }; // Other products isValid is true
            }
          );
          setListProduct(updatedProducts);
        }
      })
      .catch((error) => {
        toast.error("error", error);
      });
  };

  useEffect(() => {
    doGetPurchaceById();
  }, []);

  const handleDelete = (product: any) => {
    setProductToDelete(product);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const closeModalStoreSelect = () => {
    setModalStoreSelectIsOpen(false);
  };
  const closeModalEditDate = () => {
    setProductToEdit(null);
    setModalEditDate(false);
  };
  const validAchat = () => {
    setModalStoreSelectIsOpen(true);
  };
  const showDetail = (productId: any) => {
    const updatedProducts = listProduct.map((prod: any) => {
      if (prod?._id === productId) {
        return { ...prod, isOpen: !prod?.isOpen };
      }
      return prod;
    });

    setListProduct(updatedProducts); // Assuming you have a setListProduct state setter
  };
  const handleDate = (product: any) => {
    setProductToEdit(product);

    setModalEditDate(true);
  };
  const handleValidate = () => {};
  return (
    <Layer title={"Purchace Details"}>
      <div className="content-products-and-bar">
        <div className="header-details-purchace">
          <span className="title-purchace">
            {capitalizeFirstLetter(state?.purchace?.name)}
          </span>
        </div>
        <div className="purchace-detail-body">
          <div className="table-container-pcd">
            <table className="table-style-pcd">
              <thead style={{ height: 60 }}>
                <tr>
                  <th className="title-txt">item</th>
                  <th className="title-txt">Date d'expiration</th>
                  <th className="title-txt">Qte</th>
                  <th className="title-txt">Poids</th>
                  <th className="title-txt">PU</th>
                  <th className="title-txt"></th>
                </tr>
              </thead>
              <tbody style={{ paddingTop: 20 }}>
                {listProduct?.length > 0 ? (
                  listProduct?.map((prd: any, index: any) => {
                    console.log("ddddd", prd?.isDeleted);

                    return (
                      <>
                        <tr
                          key={index}
                          className={`line-table-style ${
                            !!prd?.isDeleted
                              ? "valid-product"
                              : "invalid-product"
                          }`}
                        >
                          <td
                            className={`td-right ${
                              prd?.isDeleted
                                ? "valid-product"
                                : "invalid-product"
                            }`}
                          >
                            <p className="td-right-text-style">
                              <p
                                style={{
                                  height: "10%",
                                  color: !!prd?.isDeleted ? "#A3A3A3" : "",
                                }}
                              >
                                {prd?.name}
                              </p>
                            </p>
                          </td>
                          <td
                            className={`td-center-date ${
                              prd?.isDeleted
                                ? "valid-product"
                                : "invalid-product"
                            }`}
                          >
                            <p
                              className="td-center-txt-date"
                              style={{
                                height: "10%",
                                color: !!prd?.isDeleted ? "#A3A3A3" : "",
                              }}
                            >
                              {prd?.expirationDate
                                ? formatDateAndTime(prd?.expirationDate)
                                : "--"}{" "}
                              <i
                                className="bx bx-edit"
                                onClick={() => { !prd?.isValidated && handleDate(prd)}}
                                
                                style={{
                                  marginLeft: 10,
                                  fontSize: 22,
                                  color: "#a3a3a3",
                                }}
                              ></i>
                            </p>
                          </td>
                          <td
                            className={`td-center ${
                              prd?.isDeleted
                                ? "valid-product"
                                : "invalid-product"
                            }`}
                          >
                            <p
                              className="td-center-txt"
                              style={{
                                height: "10%",
                                color: !!prd?.isDeleted ? "#A3A3A3" : "",
                              }}
                            >
                              {prd?.quantity}
                            </p>
                          </td>
                          <td
                            className={`td-center ${
                              prd?.isDeleted
                                ? "valid-product"
                                : "invalid-product"
                            }`}
                          >
                            <p
                              className="td-center-txt"
                              style={{
                                height: "10%",
                                color: !!prd?.isDeleted ? "#A3A3A3" : "",
                              }}
                            >
                              {prd?.portion} {prd?.product?.unit}
                            </p>
                          </td>
                          <td
                            className={`td-left ${
                              prd?.isDeleted
                                ? "valid-product"
                                : "invalid-product"
                            }`}
                          >
                            <p
                              className="td-left-txt"
                              style={{
                                height: "10%",
                                color: !!prd?.isDeleted ? "#A3A3A3" : "",
                              }}
                            >
                              {prd?.product?.cost} DT
                            </p>
                          </td>
                          <>
                            {!prd?.isDeleted ? (
                              <div className="btns-content">
                                <button
                                  className={
                                    prd?.isValidated
                                      ? "btn-check-circle"
                                      : "btn-pick-date"
                                  }
                                  onClick={() => {
                                    !prd?.isValidated
                                      ? handleDate(prd)
                                      : handleValidate();
                                  }}
                                >
                                  <i
                                    className={
                                      prd?.isValidated
                                        ? "bx bx-check-circle"
                                        : "bx bx-calendar"
                                    }
                                    style={{
                                      color: prd?.isValidated
                                        ? "#fff"
                                        : "#159b7b",
                                      fontSize: 24,
                                    }}
                                  ></i>
                                </button>
                                {purchase?.status != "validated" ? (
                                  <button
                                    className="btn-delete"
                                    onClick={() => handleDelete(prd)}
                                  >
                                    <i
                                      className="bx bx-trash"
                                      style={{ color: "#EA504F", fontSize: 24 }}
                                    ></i>
                                  </button>
                                ) : (
                                  <button
                                    className="btn-delete-inactive"
                                    disabled={true}
                                  >
                                    <i
                                      className="bx bx-trash"
                                      style={{ color: "#a3a3a3", fontSize: 24 }}
                                    ></i>
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div className="btns-content">
                                <button
                                  className="btn-pick-date"
                                  onClick={() => showDetail(prd?._id)}
                                >
                                  <i
                                    className={
                                      prd?.isOpen ? "bx bx-x" : "bx bx-show"
                                    }
                                    style={{
                                      color: prd?.isOpen ? "#909090" : "#000",
                                      fontSize: 24,
                                    }}
                                  ></i>
                                </button>
                              </div>
                            )}
                          </>
                        </tr>
                        <td colSpan={4}>
                          {!!prd?.isDeleted && prd?.isOpen ? (
                            <div className="raison-cancel-container">
                              <span style={{ marginBottom: 15 }}>
                                {" "}
                                {prd?.deleteReason}
                              </span>
                              <div className="reason-container">
                                <p>{prd?.note}</p>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
            <div className="buttom-tab-container">
              {purchase?.status != "validated" && (
                <button className="btn-cancel">Annuler</button>
              )}
              <button
                className={
                  purchase?.status == "validated"
                    ? "btn-confirm-disabled"
                    : "btn-confirm"
                }
                disabled={purchase?.status == "validated" ? true : false}
                onClick={() => validAchat()}
              >
                {purchase?.status == "validated" ? "Achat validé " : "Validate"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <DeleteProductPurchase
          product={productToDelete}
          orderId={id}
          doGetPurchaceById={doGetPurchaceById}
        />
      </Modal>
      <Modal
        isOpen={modalStoreSelectIsOpen}
        onRequestClose={closeModalStoreSelect}
        style={customStylesStoreSelect}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {/* <DeleteProductPurchase /> */}
        <SelectStore
          orderId={id}
          onClose={closeModalStoreSelect}
          doGetPurchaceById={doGetPurchaceById}
        />
      </Modal>
      <Modal
        isOpen={modalEditDate}
        onRequestClose={closeModalEditDate}
        style={customStylesEditDate}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {/* <DeleteProductPurchase /> */}
        <ExperitionDate
          product={productToEdit}
          orderId={id}
          onClose={closeModalEditDate}
          doGetPurchaceById={doGetPurchaceById}
        />
      </Modal>
    </Layer>
  );
}

export default PurchaceDetails;
