import { useEffect, useState } from "react";
import HeaderComponent from "../../Header";
import SideBar from "../../SideBar";
import "./index.scss";
import AlimentationForm from "../../AlimentationForm";
import getAllProducts from "../../../services/get-list-products";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import getAllStores from "../../../services/get-all-stores";
import { useParams } from "react-router-dom";
import Layer from "../../Layer";

const TransferScreen = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [listStores, setListStores] = useState([]);
  // const [selectedOptions , setSelectedOptions] = useState(null)
  const [selectedStore, setSelectedStore] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { storeId } = useParams();
  
  console.log("storeId" , storeId);
  
  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768; // Adjust the breakpoint as needed
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check on component mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [listProducts, setListProducts] = useState([]);

  const doGetAllStores = () => {
    setLoading(true);

    getAllStores()
      .then((response: any) => {
        setLoading(false);
        if (response?.data?.status === 500) {
          toast.error("Internal server error");
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
        }
        if (response?.data?.success) {
          setListStores(response?.data?.content);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  const doGetAllProducts = () => {
    setLoading(true);
    getAllProducts()
      .then((response: any) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setListProducts(response?.data?.content);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };
  const updatedProducts = listProducts.map((product: any) => ({
    ...product,
    value: product.name,
    label: product.name,
    quantity: 1,
  }));

  const productsState = useSelector(
    (state: Storage) => state.entities.transactionDataSlice.products
  );

  const filteredOptions = updatedProducts.filter((option: any) => {
    return !productsState?.some(
      (selected: any) => selected.value === option.value
    );
  });

  // const storesOptions = listStores
  // const storesOptions = listStores.map((store: any) => ({
  //   ...store,
  //   value: store.name,
  //   label: store.name,
  // }));
  const storesOptions = listStores.filter((store: any) => store?.name !== "General Stock").map((store: any) => ({
    ...store,
    value: store.name,
    label: store.name,
  }));
  useEffect(() => {
    doGetAllProducts();
    doGetAllStores();
  }, []);

  return (
  <Layer title={"alimentation"}>

        <div className="content-products-and-bar">
          <HeaderComponent title="Alimenter un store" />
          <div className="products-container">
            {loading ? (
              <p>Loading ...</p>
            ) : (
              <AlimentationForm
                listProducts={listProducts}
                updatedProducts={updatedProducts}
                filteredOptions={filteredOptions}
                listStores={listStores}
                storesOptions={storesOptions}
                selectedStore={selectedStore}
                setSelectedStore={setSelectedStore}
                storeId={storeId}
              />
            )}
          </div>
        </div>
  </Layer>
  );
};
export default TransferScreen;
