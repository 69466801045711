// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-details-header-container {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.store-details-header-container .store-details-left-side {
  display: flex;
  flex-direction: row;
}
.store-details-header-container .store-details-left-side .store-header-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.store-details-header-container .store-details-left-side .store-header-details .store-details-store-name {
  color: #159B7B;
  font-size: 20px;
  font-weight: 700;
}
.store-details-header-container .store-details-right-side .store-details-btn-aliment {
  border-width: 0px;
  background-color: #159b7b;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  padding: 5px;
  min-width: 180px;
  border-radius: 4px;
}
.store-details-header-container .store-details-right-side .store-details-btn-aliment .alimenter-txt {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/StoreDetailsHeader/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;AAEJ;AADI;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAGR;AAFM;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAIR;AACI;EACE,iBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACN;AAAM;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAER","sourcesContent":[".store-details-header-container {\n  width: 100%;\n  height: 20vh;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding-left: 15px;\n  padding-right: 15px;\n  .store-details-left-side {\n    display: flex;\n    flex-direction: row;\n    .store-header-details{\n        height: 100%;\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n      .store-details-store-name{\n        color: #159B7B;\n        font-size: 20px;\n        font-weight: 700;\n      }\n    }\n  }\n  .store-details-right-side {\n    .store-details-btn-aliment {\n      border-width: 0px;\n      background-color: #159b7b;\n      display: flex;\n      align-items: center;\n      justify-content: space-around;\n      flex-direction: row;\n      padding: 5px;\n      min-width: 180px;\n      border-radius: 4px;\n      .alimenter-txt {\n        color: #fff;\n        font-size: 16px;\n        font-weight: 700;\n        margin-top: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
