import { useEffect, useLayoutEffect, useState } from "react";
import "./index.scss";
import SideBar from "../../SideBar";
import HeaderComponent from "../../Header";
import ListSectionProduct from "../../ListSectionProduct";
import Modal from "react-modal";
import ModalAddProduct from "../../modals/ModalAddProduct";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import getProductsByCategoryStore from "../../../services/get-products-by-category-store";
import Lottie from "react-lottie";
import { useLocation } from "react-router-dom";

import {
  defaultOptionsEmpty,
  defaultOptionsLoading,
} from "../../../assets/lottie-animations/animationOptions";
import Layer from "../../Layer";
import getStockGeneralData from "../../../services/get-stock-general-data";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "50vh",
    minHeight: 500,
    // width: "30%",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
const customStylesPermission = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "30vh",
    // width: "30%",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const ProductDetailsScreen = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [addProductOpen, setAddProductOpen] = useState(false);
  const [listProductByCategory, setListProductByCategory] = useState([]);
  const [productsWeight, setProductsWeight] = useState<any>();
  const { storeId, categoryId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [productId, setProductId] = useState("");
  const [averageCost, setAverageCost] = useState<any>();
  const [productToEditData, setProductToEditData] = useState({});
  const [deletePermission, setDeletePermission] = useState(false);
  const { height, width } = useWindowDimensions();
  const [dataGeneral, setDataGeneral] = useState<any>(null);
  const location = useLocation();
  const [updatedListProds, setUpdatedListProds] = useState([]);

  const state = location.state;

  const closeAddProductModal = () => {
    setAddProductOpen(false);
    setDeletePermission(false);
    setEditMode(false);
    setProductId("");
  };

  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const doGetProductsByCategoryStore = () => {
    setLoading(true);
    getProductsByCategoryStore(storeId, categoryId)
      .then((response: any) => {
        setLoading(false);

        if (response.data.success) {
          const updatedList: any = response.data.content?.products?.map(
            (product: any) => ({
              ...product,
              isOpen: false,
            })
          );
          setListProductByCategory(updatedList);
          setProductsWeight(response.data.content?.productsWeight);
          setAverageCost(response?.data?.content?.averageCost);
        } else {
          if (response.data.status === 500) {
            toast.error("server error");
            return;
          }
          if (!response.data.success) {
            toast.error(response.data.message);
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const doGetGeneralData = () => {
    setLoading(true);
    getStockGeneralData(state?.product?._id)
      .then((response) => {
        setLoading(false);

        if (response?.data?.success) {
          setDataGeneral(response?.data?.content);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  useEffect(() => {
    if (storeId) {
      doGetProductsByCategoryStore();
    } else {
      doGetGeneralData();
    }
  }, []);
  console.log(dataGeneral);
  useEffect(() => {
    const updatedList: any = listProductByCategory.map((product: any) => ({
      ...product,
      isOpen: false,
    }));
    setUpdatedListProds(updatedList);
  }, []);
  return (
    <>
      <Layer>
        {loading ? (
          <div className="animation-wrapper">
            <Lottie
              options={defaultOptionsLoading}
              height={"60%"}
              width={"35%"}
            />
          </div>
        ) : dataGeneral?.products?.length > 0 ||
          listProductByCategory?.length > 0 ? (
          <div className="content-products-and-bar">
            <HeaderComponent
              title={`Produits/${state?.product?.name}`}
              details={true}
              setAddProductOpen={setAddProductOpen}
              storeDetails={state?.storeDetails}
            />
            <div className="section-products-container">
              <ListSectionProduct
                setProductToEditData={setProductToEditData}
                setAddProductOpen={setAddProductOpen}
                listProductByCategory={
                  storeId ? listProductByCategory : dataGeneral?.products
                }
                averageCost={storeId ? averageCost : dataGeneral?.averageCost}
                productsWeight={
                  storeId ? productsWeight : dataGeneral?.totalWeight
                }
                setEditMode={setEditMode}
                setProductId={setProductId}
                doGetProductsByCategoryStore={doGetProductsByCategoryStore}
                setUpdatedListProds={setUpdatedListProds}
                updatedListProds={
                  storeId ? listProductByCategory : updatedListProds
                }
                storeId={storeId}
                storeDetails={storeId ? state?.storeDetails : null}
              />
            </div>
          </div>
        ) : (
          <div className="content-products-and-bar">
            <HeaderComponent
              title={`Produits/${state?.product?.name}`}
              details={true}
              setAddProductOpen={setAddProductOpen}
            />
            <div className="animation-wrapper">
              <Lottie
                options={defaultOptionsEmpty}
                height={"60%"}
                width={"30%"}
              />
            </div>
          </div>
        )}

        <Modal
          isOpen={addProductOpen}
          onRequestClose={closeAddProductModal}
          style={
            Boolean(productId) && deletePermission
              ? customStylesPermission
              : customStyles
          }
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <ModalAddProduct
            productToEditData={productToEditData}
            deletePermission={deletePermission}
            setDeletePermission={setDeletePermission}
            closeAddModal={closeAddProductModal}
            categoryId={categoryId}
            doGetProductsByCategoryStore={doGetProductsByCategoryStore}
            editMode={editMode}
            productId={productId}
            storeId={storeId}
            doGetGeneralData={doGetGeneralData}
          />
        </Modal>
      </Layer>
    </>
  );
};
export default ProductDetailsScreen;
