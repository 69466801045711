import "./index.scss";

import Card from "../card";

import { product } from "../../utils/types/product";

type ListCardProps = {
  setIsOpen?: Function;
  listCategories?: any;
  listCategoriesInStore?: any;
  inStore?: any;
  storeId?: any;
  storeDetails?: any;
  doEditCategory?: any;
};

function ListCard({
  setIsOpen = () => {},
  listCategories,
  listCategoriesInStore,
  inStore,
  storeId,
  storeDetails,
  doEditCategory,
}: ListCardProps): JSX.Element {
  return (
    <div className="Cards-content-list">
      {Boolean(inStore) ? (
        listCategoriesInStore?.length > 0 ? (
          listCategoriesInStore.map((product: product, index: number) => (
            <Card
              key={index}
              product={product}
              storeId={storeId}
              inStore={inStore}
              storeDetails={storeDetails}
            />
          ))
        ) : (
          <></>
        )
      ) : listCategories?.length > 0 ? (
        listCategories.map((product: product, index: number) => (
          <Card
            key={index}
            product={product}
            inStore={inStore}
            storeDetails={storeDetails}
            doEditCategory={doEditCategory}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

export default ListCard;
